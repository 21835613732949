<div class="loader-center-container full-height" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!isPageLoading">
  <!-- Header -->
  <div
    class="container-fluid news-page-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left">
          <h1
            class="mega-title--page-title news-page text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="ml-0">
          <div class="row pl-4">
            <div class="col-md-12">
              <h3
                class="mb-4 text-shadow"
                [ngClass]="
                  content?.header?.subheadingColour
                    ? content?.header?.subheadingColour
                    : 'white'
                "
              >
                {{ content?.header?.subheading }}
              </h3>
              <div
                *ngFor="let action of content?.header?.actions"
                class="d-inline-block mb-2 mb-md-0"
              >
                <button-text
                  class="{{ action.isPrimary ? 'mr-2' : 'mr-2' }}"
                  buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Large news section -->
  <div
    class="container-fluid py-3 my-3 py-md-5 my-md-5 svg-bg global--news"
    *ngIf="content?.body?.sections[0]?.article?.headline"
  >
    <div class="container-xl h-100">
      <div class="row d-flex">
        <div class="col-md-8 offset-md-4">
          <div class="heading-border-right">
            <h1 class="section-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card-tags pb-2">
            <span
              *ngFor="
                let category of content?.body?.sections[0]?.article?.categories
              "
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
            >
              {{ category }}</span
            >
          </div>
          <div>
            <h2 class="xlarge-heading">
              {{ content?.body?.sections[0]?.article?.headline }}
            </h2>
            <p
              class="p-length-md"
              [innerHTML]="content?.body?.sections[0]?.article?.excerpt"
            ></p>
            <div class="mt-2">
              <button-text
                buttonStyle="solid"
                label="Read more"
                (click)="goToURI(content?.body?.sections[0]?.article?.uri)"
              ></button-text>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <img
            [src]="content?.body?.sections[0]?.article?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
    </div>
  </div>

  <!--social media video-->
  <div class="container-xl py-2 my-2 py-md-3 my-md-3">
    <div class="row">
      <div
        class="col-md-4"
        *ngFor="let video of content?.body?.sections[1]?.videos"
      >
        <h4 class="small-heading cyan mt-4 mt-md-0">{{ video?.heading }}</h4>
        <div class="greybg-right p-2">
          <!--social icons-->
          <div class="row d-flex justify-content-end mb-2">
            <button
              class="button simple-icon"
              (click)="helperService.shareToMail(content.header.heading)"
            >
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button
              class="button simple-icon"
              (click)="helperService.shareToLinkedIn()"
            >
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button
              class="button simple-icon"
              (click)="helperService.shareToTwitter()"
            >
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button
              class="button simple-icon"
              (click)="helperService.shareToFb()"
            >
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button
              class="button simple-icon"
              (click)="helperService.shareToWhatsApp()"
            >
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
          <!--social icons-->
          <!-- video -->
          <div
            class="embed-responsive embed-responsive-16by9 card-round significant-raise"
          >
            <iframe
              class="embed-responsive-item"
              [src]="video?.url | safe"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <!-- /video -->
        </div>
      </div>
    </div>
  </div>

  <!-- Recommended articles section -->
  <div class="container-fluid py-2 my-2 py-md-3 my-md-3">
    <div class="container-xl">
      <div>
        <h2 class="mb-4">{{ content?.body?.sections[2]?.heading }}</h2>
      </div>
      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let article of content?.body?.sections[2]?.articles"
        >
          <img
            class="card-img-top"
            [src]="article?.image"
            alt="Card image cap"
          />
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2">
              <span
                role="button"
                class="basic-badge"
                data-toggle="modal"
                *ngFor="let category of article?.categories"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ article.headline }}</h5>
            <p class="body-small" [innerHTML]="article.excerpt"></p>
            <a (click)="goToURI(article.uri)" class="textlink mt-auto"
              >Read more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Archive newsletters -->
  <div class="container-xl py-2 my-2 py-md-3 my-md-3" id="archive">
    <div
      class="row mt-5"
      *ngIf="content?.body?.otherSections[1].factSheets.categories.length > 0"
    >
      <div class="col-12">
        <div class="heading-border-left" id="archive-newsletter">
          <h2>Archives</h2>
        </div>
      </div>
      <div
        class="col-md-6"
        *ngFor="
          let category of content?.body?.otherSections[1].factSheets.categories;
          let i = index
        "
      >
        <!-- acccordion -->
        <div
          class="accordion mb-5 mb-md-0"
          [id]="'archive' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'newsletter' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsenewsletter' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsenewsletter' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsenewsletter' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'newsletter' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="'#archive' + removeWhiteSpace(category.name)"
            >
              <div class="accordian-body">
                <ul class="p-0" *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>
  </div>
</div>
