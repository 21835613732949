<app-banner></app-banner>
<!-- Slim Header & Main Nav -->
<nav class="navbar sticky-top main" [class.sticky]="sticky" #stickyMenu>
  <div class="container-fluid justify-content-end">
    <div class="d-flex">
      <a
        (click)="toggleMenu()"
        class="button black small simple mr-2 d-none d-md-inline-flex"
        href="https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf"
        target="_blank"
        >Privacy</a
      >
      <button
        class="button small simple mr-2 d-none d-md-inline-flex"
        [routerLink]="['/forms-and-Facts-sheets']"
        (click)="toggleMenu()"
      >
        Forms and fact sheets
      </button>
      <!-- <button
        class="button small solid mr-2 d-none d-md-inline-flex"
        [routerLink]="['/getting-started']"
        routerLinkActive="active outline"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="toggleMenu()"
      >
        Start your journey
      </button> -->
      <a
        class="button black small shaded mr-2 d-none d-md-inline-flex"
        [href]="advisersUrl"
        target="_blank"
        (click)="toggleMenu()"
      >
        Find an adviser
      </a>
      <button
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-target="#collapse-2"
        class="button small outline simple-icon-text dropdown-toggle mr-2"
        (click)="toggleMenu()"
      >
        Log in
        <div
          class="dropdown-menu noticeable-raise country-select pb-2"
          data-parent="#collapse-2"
          id="collapse-2"
          style="margin-top: 32px; max-width: 240px"
        >
          <div class="d-flex flex-column">
            <button
              class="button nav-left dropdown-button"
              (click)="logInMyPSG()"
            >
              myPSG
            </button>
            <button
              class="button nav-left dropdown-button"
              (click)="logInWealth()"
            >
              Wealth Funds
            </button>
            <button
              class="button nav-left dropdown-button"
              (click)="logInPSGOnline()"
            >
              PSG Online
            </button>
            <button
              class="button nav-left dropdown-button"
              (click)="logInAll()"
            >
              All platform access
            </button>
          </div>
        </div>
      </button>
      <div
        class="devider-vertical mr-1 d-none d-md-inline-flex align-self-center"
      ></div>
      <button
        data-toggle="dropdown"
        data-target="#collapse-1"
        aria-haspopup="true"
        aria-expanded="false"
        class="button small simple-icon-text dropdown-toggle"
        (click)="toggleMenu()"
      >
        <icon-font
          name="icon-location"
          colour="black"
          class="body-large m-n2"
        ></icon-font
        >Southern Africa
        <div
          class="dropdown-menu noticeable-raise country-select pb-2"
          data-parent="#collapse-1"
          id="collapse-1"
          style="margin-top: 32px; max-width: 240px"
        >
          <div class="d-flex flex-column">
            <p class="px-3 pt-3"><b>Select your region</b></p>
            <button
              class="button nav-left dropdown-button"
              (click)="goToLocalSite()"
            >
              Southern Africa
            </button>
            <button
              class="button nav-left dropdown-button"
              (click)="goToGlobalSite()"
            >
              Global
            </button>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['home']" (click)="toggleMenu()"
      ><img
        class="logo-img"
        src="/assets/images/logo/PSG-Financial-Services.svg"
        alt="PSG Financial Services"
    /></a>
    <div class="d-none d-lg-block">
      <!-- Menu Item Left -->

      <!-- Financial advise -->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="financialAdviceActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav noticeable-raise menu-item"
        >
          <div class="container-fluid align-items-start justify-content-start">
            <div class="row">
              <!-- Col 1 For yourself -->
              <div class="col-md-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleFinancialAdvice()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedFinancialAdviceSubMenu == 'forYourself' &&
                      !showFinancialAdviceItems
                  }"
                >
                  For yourself
                </p>
                <div
                  [ngClass]="
                    financialAdviceActive && showFinancialAdviceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >What is financial planning?</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'investment-advice'
                    "
                    fragment="investment-advice"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Investment advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'retirement-planning-advice'
                    "
                    fragment="retirement-planning-advice"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Retirement planning</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'insurance-advice'
                    "
                    fragment="insurance-advice"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Insurance advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'life-and-health-cover-advice'
                    "
                    fragment="life-and-health-cover-advice"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Life & health cover advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-your-estate']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Wills, trusts and estate planning</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['/financial-advice/find-an-adviser']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleFinancialAdvice('forYourself')"
                    >Find an adviser</a
                  >
                </div>
              </div>
              <!-- Col 2 For your business -->
              <div class="col-md-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleFinancialAdvice()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedFinancialAdviceSubMenu == 'forYourBusiness' &&
                      !showFinancialAdviceItems
                  }"
                >
                  For your business
                </p>
                <div
                  [ngClass]="
                    financialAdviceActive && showFinancialAdviceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-your-employees']"
                    [class.nav-link-active]="(activeFragment | async) === ''"
                    fragment
                    (click)="toggleFinancialAdvice('forYourBusiness')"
                    >Employee benefit consulting</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/for-your-employees']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'business-insurance'
                    "
                    fragment="business-insurance"
                    (click)="toggleFinancialAdvice('forYourBusiness')"
                    >Insurance advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['financial-advice/find-an-adviser']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleFinancialAdvice('forYourBusiness')"
                    >Find an employee benefits adviser</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showFinancialAdviceManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            [routerLink]="['financial-advice']"
            (click)="toggleFinancialAdvice('forYourself')"
            [ngClass]="{
              'nav-link-bar-active':
                selectedFinancialAdviceSubMenu != '' || financialAdviceActive
            }"
          >
            Financial advice</a
          >
        </div>
      </div>

      <!-- Save & Invest -->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="saveInvestActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav noticeable-raise menu-item"
        >
          <div class="container-fluid align-items-start justify-content-start">
            <!-- top row -->
            <div class="d-flex flex-row">
              <!-- Col 1 savings -->
              <div class="w-50">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'savings' &&
                      !showSaveInvestItems
                  }"
                >
                  Savings
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('savings')"
                    >Save & Invest</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/savings']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('savings')"
                    >Save for a rainy day</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/savings']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('savings')"
                    >Save for an education</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/savings']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('savings')"
                    >Save for a goal</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['savings']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('savings')"
                    >Savings</a
                  >
                </div>
              </div>
              <!-- Col 2 Investment products -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'investmentProducts' &&
                      !showSaveInvestItems
                  }"
                >
                  Investment products
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="[
                      'save-and-invest/compare-investment-products'
                    ]"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >Compare investment products</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/investment-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'tax-free-investment-plan'
                    "
                    fragment="tax-free-investment-plan"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >Tax Free Investment Plan</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/investment-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'voluntary-investment-plan'
                    "
                    fragment="voluntary-investment-plan"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >Voluntary Investment plan</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/investment-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'offshore-voluntary-investment-plan'
                    "
                    fragment="offshore-voluntary-investment-plan"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >Offshore voluntary investment plan</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/investment-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'endowment-investment-plan'
                    "
                    fragment="endowment-investment-plan"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >Endowment</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/how-to-invest']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >How to invest</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/psg-cash-account']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('investmentProducts')"
                    >PSG Cash Account</a
                  >
                </div>
              </div>
              <!-- Col 3 Local and global funds-->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'globalFunds' &&
                      !showSaveInvestItems
                  }"
                >
                  Local and global funds
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['all-psg-funds']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('globalFunds')"
                    >All PSG funds</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['psg-multi-managed-funds']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('globalFunds')"
                    >PSG Multi-managed funds</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['psg-single-managed-funds']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('globalFunds')"
                    >PSG Single-managed funds</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['all-funds']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('globalFunds')"
                    >All funds</a
                  >
                </div>
              </div>
              <!-- Col 4 For your employees-->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'employees' &&
                      !showSaveInvestItems
                  }"
                >
                  For your employees
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-fund-services']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('employees')"
                    >Retirement fund services</a
                  >
                </div>
              </div>
              <!-- Col 1 Retirement planning -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'retirementPlanning' &&
                      !showSaveInvestItems
                  }"
                >
                  Retirement planning
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-planning']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('retirementPlanning')"
                    >Save for retirement</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-planning']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('retirementPlanning')"
                    >Drawing a retirement income</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-planning']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('retirementPlanning')"
                    >Preserving your pension</a
                  >
                </div>
              </div>
              <!-- Col 2 Retirement products -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 w-100 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'retirementProducts' &&
                      !showSaveInvestItems
                  }"
                >
                  Retirement products
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'retirement-annuity'
                    "
                    fragment="retirement-annuity"
                    (click)="toggleSaveAndInvest('retirementProducts')"
                    >Retirement Annuity</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'living-annuity'
                    "
                    fragment="living-annuity"
                    (click)="toggleSaveAndInvest('retirementProducts')"
                    >Living Annuity</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'preservation-fund'
                    "
                    fragment="preservation-fund"
                    (click)="toggleSaveAndInvest('retirementProducts')"
                    >Preservation fund</a
                  >
                </div>
              </div>
              <!-- Col 3 Stockbroking -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'stockbroking' &&
                      !showSaveInvestItems
                  }"
                >
                  Stockbroking
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/stockbroking']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('stockbroking')"
                    >Stockbroking offering</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/stockbroking']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'managed-equity-stockbroking-portfolio'
                    "
                    fragment="managed-equity-stockbroking-portfolio"
                    (click)="toggleSaveAndInvest('stockbroking')"
                    >Managed Stockbroking</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/stockbroking']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'direct-equity-stockbroking-portfolio'
                    "
                    fragment="direct-equity-stockbroking-portfolio"
                    (click)="toggleSaveAndInvest('stockbroking')"
                    >Direct Stockbroking</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/educate-yourself']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('stockbroking')"
                    >Tutorials</a
                  >
                </div>
              </div>
              <!-- Col 4 Investment Calculators -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleSaveAndInvest()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedSaveInvestSubMenu == 'calculators' &&
                      !showSaveInvestItems
                  }"
                >
                  Calculators
                </p>
                <div
                  [ngClass]="
                    saveInvestActive && showSaveInvestItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/goal-calculator']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('calculators')"
                    target="_blank"
                    >Save for a goal calculator</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/education-calculator']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('calculators')"
                    target="_blank"
                    >Save for education calculator</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/retirement-calculator']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleSaveAndInvest('calculators')"
                    target="_blank"
                    >Save for retirement calculator</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showSaveInvestManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            [routerLink]="['save-and-invest']"
            (click)="toggleSaveAndInvest('savings')"
            [ngClass]="{
              'nav-link-bar-active':
                selectedSaveInvestSubMenu != '' || saveInvestActive
            }"
          >
            Save & Invest</a
          >
        </div>
      </div>

      <!-- Insurance -->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="insuranceActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav noticeable-raise menu-item"
        >
          <div class="container-fluid align-items-start justify-content-start">
            <div class="row">
              <!-- Col 1 For your things -->
              <div class="col-md-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleInsurence()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedInsuranceSubMenu == 'yourThings' &&
                      !showInsurenceItems
                  }"
                >
                  For your things
                </p>
                <div
                  [ngClass]="
                    insuranceActive && showInsurenceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-things']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'motor'
                    "
                    (click)="toggleInsurence('yourThings')"
                    fragment="motor"
                    >Motor</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-things']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'home-contents-and-possessions'
                    "
                    (click)="toggleInsurence('yourThings')"
                    fragment="home-contents-and-possessions"
                    >Home, contents and possessions</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-things']"
                    [class.nav-link-active]="
                      (activeFragment | async) ===
                      'liability-and-accident-cover'
                    "
                    (click)="toggleInsurence('yourThings')"
                    fragment="liability-and-accident-cover"
                    >Liability and accident cover</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-things']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'value-add-insurance-cover'
                    "
                    (click)="toggleInsurence('yourThings')"
                    fragment="value-add-insurance-cover"
                    >Value add insurance cover</a
                  >
                </div>
              </div>

              <!-- Col 2 For yourself -->
              <div class="col-md-3 pl-1">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleInsurence()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedInsuranceSubMenu == 'yourself' &&
                      !showInsurenceItems
                  }"
                >
                  For yourself
                </p>
                <div
                  [ngClass]="
                    insuranceActive && showInsurenceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'medical-aid'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="medical-aid"
                    >Medical aid</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'gap-cover'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="gap-cover"
                    >Gap cover</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'life-cover'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="life-cover"
                    >Life cover</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'income-products'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="income-products"
                    >Income products</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'disability-cover'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="disability-cover"
                    >Disability cover</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-yourself']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'dread-disease-cover'
                    "
                    (click)="toggleInsurence('yourself')"
                    fragment="dread-disease-cover"
                    >Dread disease cover</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/compare-insurance-products']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleInsurence('yourself')"
                    >Compare options</a
                  >
                </div>
              </div>

              <!-- Col 3 For your business -->
              <div class="col-md-3 pl-1">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleInsurence()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedInsuranceSubMenu == 'yourBusiness' &&
                      !showInsurenceItems
                  }"
                >
                  For your business
                </p>
                <div
                  [ngClass]="
                    insuranceActive && showInsurenceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-business']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'business-insurance'
                    "
                    (click)="toggleInsurence('yourBusiness')"
                    fragment="business-insurance"
                    >Business insurance</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-business']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'specialist-insurance'
                    "
                    (click)="toggleInsurence('yourBusiness')"
                    fragment="specialist-insurance"
                    >Specialist insurance</a
                  >
                </div>
              </div>
              <!-- Col 4 For your employees -->
              <div class="col-md-3 pl-1">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleInsurence()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedInsuranceSubMenu == 'yourEmployees' &&
                      !showInsurenceItems
                  }"
                >
                  For your employees
                </p>
                <div
                  [ngClass]="
                    insuranceActive && showInsurenceItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-employees']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'healthcare-benefits'
                    "
                    (click)="toggleInsurence('yourEmployees')"
                    fragment="healthcare-benefits"
                    >Healthcare benefits</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['insurance/for-your-employees']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'group-risk-benefits'
                    "
                    (click)="toggleInsurence('yourEmployees')"
                    fragment="group-risk-benefits"
                    >Group risk benefits</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showInsurenceManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            [routerLink]="['insurance']"
            (click)="toggleInsurence('yourThings')"
            [ngClass]="{
              'nav-link-bar-active':
                selectedInsuranceSubMenu != '' || insuranceActive
            }"
          >
            Insurance</a
          >
        </div>
      </div>
    </div>

    <span class="flex-fill"></span>

    <!-- Menu Item Right -->
    <div class="d-none d-lg-block">
      <!--Aboutus-->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="aboutUsActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav backdrop-blur noticeable-raise menu-item"
        >
          <div
            (click)="HideAboutUsMenu()"
            class="container-fluid align-items-start justify-content-start"
          >
            <div class="d-flex flex-row">
              <!-- Col 2 PSG Asset Management -->
              <div class="w-50">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleAboutUs()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedAboutUsSubMenu == 'assetManage' &&
                      !showAboutUsItems
                  }"
                >
                  PSG Asset Management
                </p>
                <div
                  [ngClass]="
                    aboutUsActive && showAboutUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['asset-management/about-us']"
                    (click)="toggleAboutUs('assetManage')"
                    >Who are we</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['asset-management/news-and-publications']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('assetManage')"
                    >News & Publications</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['asset-management/forms']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('assetManage')"
                    >Forms</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['asset-management/fact-sheets']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('assetManage')"
                    >Fund fact sheets</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['psg-single-managed-funds']"
                    (click)="toggleAboutUs('assetManage')"
                    >Funds and services</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-asset-management']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'philosophy-and-process'
                    "
                    (click)="toggleAboutUs('assetManage')"
                    fragment="philosophy-and-process"
                    >Philosophy and process</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-asset-management']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'meet-the-team'
                    "
                    (click)="toggleAboutUs('assetManage')"
                    fragment="meet-the-team"
                    >Meet the team</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="[
                      'asset-management/esg-investment-philosophy'
                    ]"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('assetManage')"
                    >ESG investment philosophy</a
                  >
                </div>
              </div>
              <!-- Col 3 PSG Insure-->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleAboutUs()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedAboutUsSubMenu == 'insure' && !showAboutUsItems
                  }"
                >
                  PSG Insure
                </p>
                <div
                  [ngClass]="
                    aboutUsActive && showAboutUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-insure']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'insure-who-are-we'
                    "
                    (click)="toggleAboutUs('insure')"
                    fragment="insure-who-are-we"
                    >Who are we</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-insure']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'advice'
                    "
                    (click)="toggleAboutUs('insure')"
                    fragment="advice"
                    >Advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-insure']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'products'
                    "
                    (click)="toggleAboutUs('insure')"
                    fragment="products"
                    >Products</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-insure']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'meet-the-team'
                    "
                    (click)="toggleAboutUs('insure')"
                    fragment="meet-the-team"
                    >Meet the team</a
                  >
                </div>
              </div>
              <!-- Col 1 PSG Wealth-->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleAboutUs()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedAboutUsSubMenu == 'wealth' && !showAboutUsItems
                  }"
                >
                  PSG Wealth
                </p>
                <div
                  [ngClass]="
                    aboutUsActive && showAboutUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-wealth']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'wealth-who-are-we'
                    "
                    (click)="toggleAboutUs('wealth')"
                    fragment="wealth-who-are-we"
                    >Who are we</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-wealth']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'wealth-advice'
                    "
                    (click)="toggleAboutUs('wealth')"
                    fragment="wealth-advice"
                    >Advice</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['save-and-invest/investment-products']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'wealth-platform'
                    "
                    (click)="toggleAboutUs('wealth')"
                    fragment="wealth-platform"
                    >Products</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['psg-multi-managed-funds']"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('wealth')"
                    routerLinkActive="nav-link-active"
                    >Funds</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/psg-wealth']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'meet-the-wealth-team'
                    "
                    (click)="toggleAboutUs('wealth')"
                    fragment="meet-the-wealth-team"
                    >Meet the team</a
                  >
                </div>
              </div>
              <!-- Col 4 About our company-->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleAboutUs()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedAboutUsSubMenu == 'company' && !showAboutUsItems
                  }"
                >
                  About our company
                </p>
                <div
                  [ngClass]="
                    aboutUsActive && showAboutUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'strategy-and-values'
                    "
                    (click)="toggleAboutUs('company')"
                    fragment="strategy-and-values"
                    >Strategy and values</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'meet-the-team'
                    "
                    (click)="toggleAboutUs('company')"
                    fragment="meet-the-team"
                    >Meet the executive team</a
                  >
                </div>
              </div>
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleAboutUs()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedAboutUsSubMenu == 'investorRelations' &&
                      !showAboutUsItems
                  }"
                >
                  Investor relations
                </p>
                <div
                  [ngClass]="
                    aboutUsActive && showAboutUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['investor-relations/overview']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('investorRelations')"
                    >Investor overview</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['investor-relations/investor-information']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('investorRelations')"
                    >Investor information</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['investor-relations/sens-and-share-data']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('investorRelations')"
                    >SENS and share data</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="[
                      'investor-relations/corporate-governance-and-responsibility'
                    ]"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('investorRelations')"
                    >Corporate governance & responsibility</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['about-us/archive']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleAboutUs('investorRelations')"
                    >Archive</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showAboutUsManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            [routerLink]="['about-us']"
            (click)="toggleAboutUs('company')"
            [ngClass]="{
              'nav-link-bar-active':
                selectedAboutUsSubMenu != '' || aboutUsActive
            }"
            >About us</a
          >
        </div>
      </div>

      <!--News & Publications-->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="newsAndPublicationActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav backdrop-blur noticeable-raise menu-item"
        >
          <div
            (click)="HideNewsAndPublicationMenu()"
            class="container-fluid align-items-start justify-content-start"
          >
            <div class="d-flex flex-row">
              <!-- Col 1 -->
              <div class="w-50">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleNewsAndPublication()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'testimonials' &&
                      !showNewsAndPublicationItems
                  }"
                >
                  Testimonials
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['news-and-publications']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleNewsAndPublication('testimonials')"
                    >View all news and publications</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['bigger-picture']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleNewsAndPublication('testimonials')"
                    >PSG Bigger Picture Thinkers</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['George-Super-Kitchen']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleNewsAndPublication('testimonials')"
                    >George Super Kitchen</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['smartboy']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleNewsAndPublication('testimonials')"
                    >Smartboy</a
                  >
                </div>
              </div>

              <!-- Col 2-->
              <div class="w-50 pl-3 pr-2">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleNewsAndPublication()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'investmentUpdate' &&
                      !showNewsAndPublicationItems
                  }"
                >
                  Daily investment update
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="row vertical-separator"
                >
                  <div class="col pr-0">
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      routerLinkActive="nav-link-active"
                      (click)="navigateToLatestPublication()"
                      >Latest</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'local-diu-funds'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="local-diu-funds"
                      >Local funds performance</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'global-diu-funds'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="global-diu-funds"
                      >Global funds performance</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'other-diu-funds'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="other-diu-funds"
                      >Invest in other currencies</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'house-diu-funds'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="house-diu-funds"
                      >House view equity portfolios</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'recent-investment-ideas'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="recent-investment-ideas"
                      >Recent investment ideas</a
                    >
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="[
                        '/news-and-publications/economic-calendar'
                      ]"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      >Economic calendar
                    </a>
                  </div>
                  <div class="col pr-0">
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) ===
                        'corporate-actions-calendar'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="corporate-actions-calendar"
                      >Corporate actions calendar
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['wealthonline']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'fund-performance'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="fund-performance"
                      >Fund performance
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['news-and-publications/']"
                      [class.nav-link-active]="
                        (activeFragment | async) === 'view-all'
                      "
                      (click)="toggleNewsAndPublication('investmentUpdate')"
                      fragment="view-all"
                      >View all</a
                    >
                  </div>
                </div>
              </div>

              <!-- Research Hub-->
              <!-- <div class="w-75 pl-4">
                <p
                  class="body-bold mb-4 submenu-heading"
                  (click)="toggleNewsAndPublication('marketsManual')"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'marketsManual' &&
                      !showNewsAndPublicationItems
                  }"
                  [routerLink]="['research-hub']"
                >
                  Research Hub
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="row vertical-separator"
                >
                  <div class="col-6 vertical-separator">
                    <p
                      class="body-bold mb-2 small research-hub-subItem"
                      [routerLink]="['research-hub/research-insights']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Research Insights
                    </p>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/equity-research']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Equity research
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/fund-research']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Fund research
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/macroeconomic-research']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Macroeconomic research
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/in-the-media']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      In the media
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/week-ahead']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                      >Week ahead
                    </a>
                  </div>
                  <div class="col-6">
                    <p
                      class="body-bold mb-2 small research-hub-subItem"
                      [routerLink]="['research-hub/solution-insights']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Solution Insights
                    </p>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="['research-hub/fund-of-funds']"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      Fund of Funds
                    </a>
                    <a
                      class="button small width-100 nav-left-mini pl-0"
                      [routerLink]="[
                        'research-hub/house-view-equity-portfolios'
                      ]"
                      routerLinkActive="nav-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="toggleNewsAndPublication('marketsManual')"
                    >
                      House View Equity Portfolios
                    </a>
                  </div>
                </div>
              </div> -->

              <!-- Col 3 -->

              <div class="w-50 pl-4">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleNewsAndPublication('directTrading')"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'directTrading' &&
                      !showNewsAndPublicationItems
                  }"
                  [routerLink]="['wealthonline']"
                >
                  Direct trading
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['wealthonline']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'investment-research'
                    "
                    (click)="toggleNewsAndPublication('directTrading')"
                    fragment="investment-research"
                    >Direct trading
                  </a>
                </div>
              </div>

              <!-- Col 4 -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleNewsAndPublication()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'events' &&
                      !showNewsAndPublicationItems
                  }"
                >
                  Our events
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['news-and-publications/events-and-webinars']"
                    (click)="toggleNewsAndPublication('events')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Events</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['news-and-publications/events-and-webinars']"
                    (click)="toggleNewsAndPublication('events')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Webinars</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="[
                      'news-and-publications/our-events/think-big-south-africa'
                    ]"
                    (click)="toggleNewsAndPublication('events')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Think Big South Africa</a
                  >
                </div>
              </div>
              <!-- Col 5 -->
              <div class="w-50 pl-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleNewsAndPublication()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedNewsAndPublicationSubMenu == 'newsletters' &&
                      !showNewsAndPublicationItems
                  }"
                >
                  Newsletters
                </p>
                <div
                  [ngClass]="
                    newsAndPublicationActive && showNewsAndPublicationItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['news-and-publications']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'archive-newsletter'
                    "
                    (click)="toggleNewsAndPublication('newsletters')"
                    fragment="archive-newsletter"
                    >Archive</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            (click)="HideNewsAndPublicationMenu()"
            class="container-fluid align-items-start justify-content-start"
          ></div>
        </div>

        <div *ngIf="showNewsAndPublicationManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            (click)="toggleNewsAndPublication('testimonials')"
            [routerLink]="['news-and-publications']"
            [ngClass]="{
              'nav-link-bar-active':
                selectedNewsAndPublicationSubMenu != '' ||
                newsAndPublicationActive
            }"
            >News & Publications</a
          >
        </div>
      </div>

      <!--careers-->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="careersActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav backdrop-blur noticeable-raise menu-item"
        >
          <div
            (click)="HideCareersMenu()"
            class="container-fluid align-items-start justify-content-start"
          >
            <div class="row">
              <!-- Col 1 -->
              <div class="col-md-3">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleCarees()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedCareersSubMenu == 'workingWithUs' &&
                      !showCareesItems
                  }"
                >
                  Working with us
                </p>
                <div
                  [ngClass]="
                    careersActive && showCareesItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator height-96"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers']"
                    [class.nav-link-active]="
                      (activeFragment | async) === 'corporate-culture'
                    "
                    (click)="toggleCarees('workingWithUs')"
                    fragment="corporate-culture"
                    >Corporate culture</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers/graduate-programme']"
                    (click)="toggleCarees('workingWithUs')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Graduate programme</a
                  >
                </div>
              </div>
              <!-- Col 2 -->
              <div class="col-md-3 pl-1">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleCarees()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedCareersSubMenu == 'workingForUs' &&
                      !showCareesItems
                  }"
                >
                  Work for us
                </p>
                <div
                  [ngClass]="
                    careersActive && showCareesItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator height-96"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleCarees('workingForUs')"
                    >Browse opportunities</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleCarees('workingForUs')"
                    >Register CV</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers']"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleCarees('workingForUs')"
                    >Log in to career portal</a
                  >
                </div>
              </div>
              <!-- Col 3 -->
              <div class="col-md-3 pl-1">
                <p
                  class="body-bold mb-2 submenu-heading"
                  (click)="toggleCarees()"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedCareersSubMenu == 'advisers' && !showCareesItems
                  }"
                >
                  For advisers
                </p>
                <div
                  [ngClass]="
                    careersActive && showCareesItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers/partner-with-psg']"
                    (click)="toggleCarees('advisers')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Partner with PSG</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    [routerLink]="['careers/become-an-adviser']"
                    (click)="toggleCarees('advisers')"
                    routerLinkActive="nav-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Become an adviser</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showCareersManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            (click)="toggleCarees('workingForUs')"
            [routerLink]="['careers']"
            [ngClass]="{
              'nav-link-bar-active':
                selectedCareersSubMenu != '' || careersActive
            }"
            >Careers</a
          >
        </div>
      </div>

      <!--Contact us-->
      <div class="menu-item" routerLinkActive="nav-child-active">
        <div
          *ngIf="contactUsActive"
          (clickOutside)="clickOutsideMenu($event)"
          class="dropdown-menu mega-nav backdrop-blur noticeable-raise menu-item"
        >
          <div
            (click)="HideContactUsMenu()"
            class="container-fluid align-items-start"
          >
            <div class="row">
              <!-- Col 1 -->
              <div class="col-md-3 pr-0">
                <div
                  (click)="toggleContactUs()"
                  class="submenu-heading"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedContactUsSubMenu == 'wealth' &&
                      !showContactUsItems
                  }"
                >
                  <p class="body-bold mb-0">PSG Wealth</p>
                  <p class="body-small slate mb-2">
                    Investments, retirement and stockbroking.
                  </p>
                </div>
                <div
                  [ngClass]="
                    contactUsActive && showContactUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator height-60"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="tel:0860 774 774"
                    (click)="toggleContactUs('wealth')"
                    style="color: #00afef !important"
                    >0860 774 774</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="mailto:clientservice@psg.co.za"
                    (click)="toggleContactUs('wealth')"
                    >clientservice@psg.co.za</a
                  >
                </div>
              </div>
              <!-- Col 2 -->
              <div class="col-md-3">
                <div
                  (click)="toggleContactUs()"
                  class="submenu-heading"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedContactUsSubMenu == 'assetManagement' &&
                      !showContactUsItems
                  }"
                >
                  <p class="body-bold mb-0">PSG Asset Management</p>
                  <p class="body-small slate mb-2">Asset management.</p>
                </div>
                <div
                  [ngClass]="
                    contactUsActive && showContactUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator height-60"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="tel:0800 600 168"
                    (click)="toggleContactUs('assetManagement')"
                    style="color: #00afef !important"
                    >0800 600 168
                  </a>
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="mailto:assetmanagement@psg.co.za"
                    (click)="toggleContactUs('assetManagement')"
                    >assetmanagement@psg.co.za</a
                  >
                </div>
              </div>
              <!-- Col 3 -->
              <div class="col-md-3 pl-1">
                <div
                  (click)="toggleContactUs()"
                  class="submenu-heading"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedContactUsSubMenu == 'insure' &&
                      !showContactUsItems
                  }"
                >
                  <p class="body-bold mb-0">PSG Insure</p>
                  <p class="body-small slate mb-2">
                    Personal and business insurance.
                  </p>
                </div>
                <div
                  [ngClass]="
                    contactUsActive && showContactUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                  class="vertical-separator height-60"
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="tel:0861101179"
                    (click)="toggleContactUs('insure')"
                    style="color: #00afef !important"
                    >0861 101 179</a
                  >
                </div>
              </div>
              <!-- Col 4 -->
              <div class="col-md-3 pl-1">
                <div
                  (click)="toggleContactUs()"
                  class="submenu-heading"
                  [ngClass]="{
                    'nav-link-bar-active':
                      selectedContactUsSubMenu == 'financial-services' &&
                      !showContactUsItems
                  }"
                >
                  <p class="body-bold mb-0">PSG Financial Services</p>
                  <p class="body-small slate mb-2">Head office.</p>
                </div>
                <div
                  [ngClass]="
                    contactUsActive && showContactUsItems
                      ? 'show-items'
                      : 'hide-items'
                  "
                >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="tel:021 918 7800"
                    (click)="toggleContactUs('financial-services')"
                    style="color: #00afef !important"
                    >021 918 7800</a
                  >
                  <a
                    class="button small width-100 nav-left-mini pl-0"
                    href="mailto:wealth@psg.co.za"
                    (click)="toggleContactUs('financial-services')"
                    >wealth@psg.co.za</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showContactUsManu">
          <a
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button nav-center-mini mr-2"
            [routerLink]="['contact-us']"
            (click)="toggleContactUs('none')"
            [ngClass]="{
              'nav-link-bar-active':
                selectedContactUsSubMenu != '' || contactUsActive
            }"
          >
            Contact us</a
          >
        </div>
      </div>
    </div>

    <!-- Small Search -->
    <button class="button simple-icon" (click)="toggleSearch('search')">
      <icon-font
        name="icon-search"
        colour="black"
        class="body-large"
      ></icon-font>
    </button>

    <!-- Mobile Nav -->
    <!-- ---------------------------------------------------------- -->
    <div>
      <div>
        <div
          id="mobileNav"
          class="dropdown-menu mega-nav mobile-nav noticeable-raise menu-item d-flex flex-column"
        >
          <div class="mobile-nav-items flex-fill">
            <!-- Menu Item -->
            <div>
              <!-- Sub-Menu Dropdown  (financial advice)-->
              <div
                id="1"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('1')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      [routerLink]="['financial-advice']"
                      (click)="toggleMobileNav('mobileNav')"
                    >
                      Financial advice
                    </a>
                  </div>

                  <!--1 Sub-Menu Item (For yourself)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#forYourself"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For yourself
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="forYourself">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >What is financial planning?</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'investment-advice'
                        "
                        fragment="investment-advice"
                        >Investment advice</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'retirement-planning-advice'
                        "
                        fragment="retirement-planning-advice"
                        >Retirement planning</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'insurance-advice'
                        "
                        fragment="insurance-advice"
                        >Insurance advice</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'life-and-health-cover-advice'
                        "
                        fragment="life-and-health-cover-advice"
                        >Life & Health cover advice</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-your-estate']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Wills, trusts and estate planning</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['support/financial-tools']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="nav-link-active"
                        >Financial tools</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/find-an-adviser']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Find an adviser</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (For yourself)-->

                  <!--2 Sub-Menu Item (For your business)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#forYourBusiness"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For your business
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="forYourBusiness">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-your-employees']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Employee benefit consulting</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'insurance-advice'
                        "
                        fragment="insurance-advice"
                        >Insurance advice</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['financial-advice/find-an-adviser']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Find an employee benefits adviser</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (For your business)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown  (financial advice)-->

              <!-- Sub-Menu Dropdown  (Save&invest)-->
              <div
                id="2"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('2')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      (click)="toggleMobileNav('mobileNav')"
                      [routerLink]="['save-and-invest']"
                    >
                      Save & Invest
                    </a>
                  </div>

                  <!--1 Sub-Menu Item (Savings)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#savings"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Savings
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="savings">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/savings']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Save for a rainy day</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/savings']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Save for an education</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/savings']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Save for a goal</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['savings']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Savings</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (Savings)-->

                  <!--2 Sub-Menu Item (Investment products)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#investmentProducts"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Investment products
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="investmentProducts">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'save-and-invest/compare-investment-products'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Compare investment products</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/investment-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'tax-free-investment-plan'
                        "
                        fragment="tax-free-investment-plan"
                        >Tax Free Investment Plan</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/investment-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'voluntary-investment-plan'
                        "
                        fragment="voluntary-investment-plan"
                        >Voluntary Investment Plan</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/investment-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'offshore-voluntary-investment-plan'
                        "
                        fragment="offshore-voluntary-investment-plan"
                        >Offshore voluntary investment Plan</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/investment-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'endowment-investment-plan'
                        "
                        fragment="endowment-investment-plan"
                        >Endowment</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/how-to-invest']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >How to invest</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/psg-cash-account']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >PSG Cash Account</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (Investment products)-->

                  <!--3 Sub-Menu Item (Local and global funds)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#funds"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Local and global funds
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="funds">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['all-psg-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >All PSG funds</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['psg-multi-managed-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >PSG Multi-managed funds</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['psg-single-managed-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >PSG Single-managed funds</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['all-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >All funds</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (Local and global funds)-->

                  <!--4 Sub-Menu Item (For your employees)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#forYourEmployees"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For your employees
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="forYourEmployees">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'save-and-invest/retirement-fund-servies'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Retirement fund services</a
                      >
                    </div>
                  </div>
                  <!--/4 Sub-Menu Item (For your employees)-->

                  <!--5 Sub-Menu Item (Retirement planning)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#retirementPlanning"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Retirement planning
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="retirementPlanning">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-planning']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Save for retirement</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-planning']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Drawing a retirement income</a
                      >

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-planning']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Preserving your pension</a
                      >
                    </div>
                  </div>
                  <!--/5 Sub-Menu Item (Retirement planning)-->

                  <!--6 Sub-Menu Item (Retirement products)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#retirementProducts"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Retirement products
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="retirementProducts">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'retirement-annuity'
                        "
                        fragment="retirement-annuity"
                        >Retirement Annuity</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'living-annuity'
                        "
                        fragment="living-annuity"
                        >Living Annuity</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-products']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'preservation-fund'
                        "
                        fragment="preservation-fund"
                        >Preservation fund</a
                      >
                    </div>
                  </div>
                  <!--/6 Sub-Menu Item (Retirement products)-->

                  <!--7 Sub-Menu Item (Stockbroking)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#stockbroking"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Stockbroking
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="stockbroking">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/stockbroking']"
                        [class.nav-link-active]="
                          (activeFragment | async) === ''
                        "
                        fragment
                        >Stockbroking offering</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/educate-yourself']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Educate Yourself
                      </a>

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/stockbroking']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'managed-equity-stockbroking-portfolio'
                        "
                        fragment="managed-equity-stockbroking-portfolio"
                        >Managed equity stockbroking portfolio</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/stockbroking']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'direct-equity-stockbroking-portfolio'
                        "
                        fragment="direct-equity-stockbroking-portfolio"
                        >Direct equity stockbroking</a
                      >
                    </div>
                  </div>
                  <!--/7 Sub-Menu Item (Stockbroking)-->

                  <!--8 Sub-Menu Item (Investment Calculators)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#investmentCalculators"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Calculators
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="investmentCalculators">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/goal-calculator']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Save for a goal calculator
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/education-calculator']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Save for an education calculator
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/retirement-calculator']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Save for retirement calculator
                      </a>
                    </div>
                  </div>
                  <!--/8 Sub-Menu Item (Investment Calculators)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown  (Save&invest)-->

              <!-- Sub-Menu Dropdown  (Insurance)-->
              <div
                id="3"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('3')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      (click)="toggleMobileNav('mobileNav')"
                      [routerLink]="['insurance']"
                    >
                      Insurance
                    </a>
                  </div>

                  <!--1 Sub-Menu Item (For your things)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#forYourThings"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For your things
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="forYourThings">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-things']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'motor'
                        "
                        fragment="motor"
                        >Motor</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-things']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'home-contents-and-possessions'
                        "
                        fragment="home-contents-and-possessions"
                        >Home, contents and possessions</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-things']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'liability-and-accident-cover'
                        "
                        fragment="liability-and-accident-cover"
                        >Liability and accident cover</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        fragment="value-add-insurance-cover"
                        [routerLink]="['insurance/for-your-things']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'value-add-insurance-cover'
                        "
                        >Value add insurance cover</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (For your things)-->

                  <!--2 Sub-Menu Item (For yourself)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#for-yourself"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For yourself
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="for-yourself">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'medical-aid'
                        "
                        fragment="medical-aid"
                        >Medical aid</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'gap-cover'
                        "
                        fragment="gap-cover"
                        >Gap cover</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'life-cover'
                        "
                        fragment="life-cover"
                        >Life cover</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'income-products'
                        "
                        fragment="income-products"
                        >Income products</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'disability-cover'
                        "
                        fragment="disability-cover"
                        >Disability cover</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-yourself']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'dread-disease-cover'
                        "
                        fragment="dread-disease-cover"
                        >Dread disease cover</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/compare-insurance-products']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Compare options</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (For yourself)-->

                  <!--3 Sub-Menu Item (For your business)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#for-your-business"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For your business
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="for-your-business">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-business']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'business-insurance'
                        "
                        fragment="business-insurance"
                        >Business insurance</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-business']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'specialist-insurance'
                        "
                        fragment="specialist-insurance"
                        >Specialist insurance</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (For your business)-->

                  <!--4 Sub-Menu Item (For your employees)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#for-your-employees"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For your employees
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="for-your-employees">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-employees']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'healthcare-benefits'
                        "
                        fragment="healthcare-benefits"
                        >Healthcare benefits</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['insurance/for-your-employees']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'group-risk-benefits'
                        "
                        fragment="group-risk-benefits"
                        >Group risk benefits</a
                      >
                    </div>
                  </div>
                  <!--/4 Sub-Menu Item (For your employees)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown  (Insurance)-->

              <!-- Sub-Menu Dropdown (About us)-->
              <div
                id="4"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('4')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      [routerLink]="['about-us']"
                      (click)="toggleMobileNav('mobileNav')"
                    >
                      About us
                    </a>
                  </div>

                  <!-- Sub-Menu Item -->

                  <!--2 Sub-Menu Item (PSG Asset Management)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#psgassetM"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      PSG Asset Management
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="psgassetM">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-asset-management']"
                        [class.nav-link-active]="
                          (activeFragment | async) === ''
                        "
                        fragment
                        >Who are we</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'asset-management/news-and-publications'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >News & Publications</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['asset-management/forms']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Forms</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['asset-management/fact-sheets']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Fund fact sheets</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['psg-single-managed-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Funds and services</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-asset-management']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'philosophy-and-process'
                        "
                        fragment="philosophy-and-process"
                        >Philosophy and process</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-asset-management']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'meet-the-team'
                        "
                        fragment="meet-the-team"
                        >Meet the team</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'about-us/psg-asset-management/esg-investment-philosophy'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >ESG investment philosophy</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (PSG Asset Management)-->

                  <!--3 Sub-Menu Item (PSG Insure)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#psgInsure"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      PSG Insure
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="psgInsure">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-insure']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'who-are-we'
                        "
                        fragment="who-are-we"
                        >Who are we</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-insure']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'advice'
                        "
                        fragment="advice"
                        >Advice</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-insure']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'products'
                        "
                        fragment="products"
                        >Products</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-insure']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'meet-the-team'
                        "
                        fragment="meet-the-team"
                        >Meet the team</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (PSG Insure)-->
                  <!--1 Sub-Menu Item (PSG Wealth)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#psgwealth"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      PSG Wealth
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="psgwealth">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-wealth']"
                        >Who are we</a
                      >

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-wealth']"
                        fragment="wealth-advice"
                        >Advice</a
                      >

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['save-and-invest/investment-products']"
                        fragment="wealth-platform"
                        >Products</a
                      >

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['psg-multi-managed-funds']"
                        >Funds</a
                      >

                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/psg-wealth']"
                        fragment="meet-the-team"
                        >Meet the team</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (PSG Wealth)-->

                  <!--4 Sub-Menu Item (About our company)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#psgAbout"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      About our company
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="psgAbout">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'strategy-and-values'
                        "
                        fragment="strategy-and-values"
                        >Strategy and values</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'meet-the-team'
                        "
                        fragment="meet-the-team"
                        >Meet the executive team</a
                      >
                    </div>
                  </div>
                  <!--/4 Sub-Menu Item (About our company)-->

                  <!--5 Sub-Menu Item (Investor relations)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#investorRelations"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Investor relations
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="investorRelations">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['investor-relations/overview']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Investor overview</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'investor-relations/investor-information'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Investor information</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'investor-relations/sens-and-share-data'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >SENS and share data</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'investor-relations/corporate-governance-and-responsibility'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Corporate governance & responsibility</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['about-us/archive']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Archive</a
                      >
                    </div>
                  </div>
                  <!--/5 Sub-Menu Item (Investor relations)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown (About us)-->

              <!-- Sub-Menu Dropdown (News & Publications)-->
              <div
                id="5"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('5')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      [routerLink]="['news-and-publications']"
                      (click)="toggleMobileNav('mobileNav')"
                    >
                      News & Publications
                    </a>
                  </div>
                  <!-- Sub-Menu Item -->

                  <!--1 Sub-Menu Item (Our news)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#ourNews"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Testimonials
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="ourNews">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['news-and-publications']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >View all news and publications</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['bigger-picture']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        PSG Bigger Picture Thinkers</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['George-Super-Kitchen']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >George Super Kitchen</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['smartboy']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Smartboy</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (Our news)-->

                  <!--2 Sub-Menu Item (Daily Investment Update)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#news-diu"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Daily Investment Update
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="news-diu">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          latestArticleURL == ''
                            ? 'news-and-publications#view-all'
                            : latestArticleURL
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Latest</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'market-indicator'
                        "
                        fragment="market-indicator"
                        >Market indicators
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'local-diu-funds'
                        "
                        fragment="local-diu-funds"
                        >Local funds performance</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'global-diu-funds'
                        "
                        fragment="global-diu-funds"
                        >Global funds performance</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'other-diu-funds'
                        "
                        fragment="other-diu-funds"
                        >Invest in other currencies</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'house-diu-funds'
                        "
                        fragment="house-diu-funds"
                        >House view equity portfolios</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'recent-investment-ideas'
                        "
                        fragment="recent-investment-ideas"
                        >Recent investment ideas</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          '/news-and-publications/economic-calendar'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Economic calendar
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) ===
                          'corporate-actions-calendar'
                        "
                        fragment="corporate-actions-calendar"
                        >Corporate actions calendar
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['wealthonline']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'fund-performance'
                        "
                        fragment="fund-performance"
                        >Fund performance
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['news-and-publications/']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'view-all'
                        "
                        fragment="view-all"
                        >View all</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (Daily Investment Update)-->

                  <!-- Research Hub -->
                  <!-- <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#marketsManual"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Research Hub
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <div class="collapse width-100" id="marketsManual">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Research Hub
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/research-insights']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Research Insights
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/equity-research']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Equity research</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/fund-research']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Fund research</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/macroeconomic-research']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Macroeconomic research</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/in-the-media']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >In the media</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/week-ahead']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Week ahead
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/solution-insights']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        Solution Insights
                      </a>
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['research-hub/fund-of-funds']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Fund of Funds</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'research-hub/house-view-equity-portfolios'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        House View Equity Portfolios
                      </a>
                    </div>
                  </div> -->

                  <!--3 Sub-Menu Item (Our events)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#events"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Our events
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="events">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'news-and-publications/events-and-webinars'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Events</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'news-and-publications/events-and-webinars'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Webinars</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="[
                          'news-and-publications/our-events/think-big-south-africa'
                        ]"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Think Big South Africa</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (Our events)-->

                  <!--4 Sub-Menu Item (Newsletter)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#newsletter"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Newsletters
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="newsletter">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['news-and-publications/']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'archive-newsletter'
                        "
                        fragment="archive-newsletter"
                        >Archive</a
                      >
                    </div>
                  </div>
                  <!--/4 Sub-Menu Item (Newsletter)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown (News & Publications)-->

              <!-- Sub-Menu Dropdown (Careers)-->
              <div
                id="6"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('6')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      (click)="toggleMobileNav('mobileNav')"
                      [routerLink]="['careers']"
                    >
                      Careers
                    </a>
                  </div>
                  <!-- Sub-Menu Item -->

                  <!--1 Sub-Menu Item (Working with us)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#workingWithUs"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Working with us
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="workingWithUs">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers']"
                        [class.nav-link-active]="
                          (activeFragment | async) === 'corporate-culture'
                        "
                        fragment="corporate-culture"
                        >Corporate culture</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers/graduate-programme']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Graduate programme</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (Working with us)-->

                  <!--2 Sub-Menu Item (Work for us)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#workForUs"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      Work for us
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="workForUs">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Browse opportunities</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Register CV</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Log in to career portal</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (Work for us)-->

                  <!--3 Sub-Menu Item (For advisers)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#forAdvisers"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      For advisers
                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>

                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="forAdvisers">
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers/partner-with-psg']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Partner with PSG</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        (click)="toggleMobileNav('mobileNav')"
                        [routerLink]="['careers/become-an-adviser']"
                        routerLinkActive="nav-link-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Become an adviser</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (For advisers)-->
                </div>
              </div>
              <!-- Sub-Menu Dropdown (Careers)-->

              <!--  Sub-Menu Dropdown (Contact Us)-->
              <div
                id="7"
                class="dropdown-menu secondary-nav noticeable-raise menu-item"
              >
                <div>
                  <!-- Nav Heading -->
                  <div class="d-flex align-items-center mb-3">
                    <!-- Back Button -->
                    <button
                      class="button simple-icon mr-2"
                      (click)="hideMobileSubMenu('7')"
                    >
                      <icon-font
                        name="icon-angle-left"
                        colour="black"
                        class="body-large"
                      ></icon-font>
                    </button>
                    <!-- Heading -->
                    <a
                      class="width-100 button nav-large mobile-nav-link-active"
                      [routerLink]="['contact-us']"
                      (click)="toggleMobileNav('mobileNav')"
                    >
                      Contact us
                    </a>
                  </div>
                  <!-- Sub-Menu Item -->

                  <!--1 Sub-Menu Item (PSG Wealth)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#contactPsgWealth"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      <div class="d-flex flex-column">
                        <div>PSG Wealth</div>
                        <p class="body-small slate mb-0">
                          Investments, retirement and stockbroking.
                        </p>
                      </div>

                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="contactPsgWealth">
                      <a class="button small width-100 nav-left-mini mb-2"
                        >0860 774 774</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        href="mailto:clientservice@psg.co.za"
                        >clientservice@psg.co.za</a
                      >
                    </div>
                  </div>
                  <!--/1 Sub-Menu Item (PSG Wealth) -->

                  <!--2 Sub-Menu Item (PSG Asset Management)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#contactPsgAsset"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      <div class="d-flex flex-column">
                        <div>PSG Asset Management</div>
                        <p class="body-small slate mb-0">Asset Management.</p>
                      </div>

                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="contactPsgAsset">
                      <a class="button small width-100 nav-left-mini mb-2"
                        >0860 600 168</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        href="mailto:assetmanagement@psg.co.za"
                        >assetmanagement@psg.co.za</a
                      >
                    </div>
                  </div>
                  <!--/2 Sub-Menu Item (PSG Asset Management) -->

                  <!--3 Sub-Menu Item (PSG Insure)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#contactPsgInsure"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      <div class="d-flex flex-column">
                        <div>PSG Insure</div>
                        <p class="body-small slate mb-0">
                          Personal and business insurance.
                        </p>
                      </div>

                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div class="collapse width-100" id="contactPsgInsure">
                      <a class="button small width-100 nav-left-mini mb-2" href="tel:0861 101 179"
                        >0861 101 179</a
                      >
                    </div>
                  </div>
                  <!--/3 Sub-Menu Item (PSG Insure) -->

                  <!--4 Sub-Menu Item (PSG Financial Services)-->
                  <div
                    class="width-100 d-flex flex-column button nav-large expand collapsed mb-3"
                    data-toggle="collapse"
                    data-target="#contactPsgFinancialServices"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <a class="width-100 button nav-large inner-button">
                      <div class="d-flex flex-column">
                        <div>PSG Financial Services</div>
                        <p class="body-small slate mb-0">Head office.</p>
                      </div>

                      <icon-font
                        name="icon-angle-up"
                        class="icon-md icon-rotate"
                        colour="black"
                      ></icon-font>
                    </a>
                    <!-- Sub-Menu Dropdown -->
                    <div
                      class="collapse width-100"
                      id="contactPsgFinancialServices"
                    >
                      <a class="button small width-100 nav-left-mini mb-2"
                        >021 918 7800</a
                      >
                      <a
                        class="button small width-100 nav-left-mini mb-2"
                        href="mailto:info@psg.co.za"
                        >info@psg.co.za</a
                      >
                    </div>
                  </div>
                  <!--/4 Sub-Menu Item (PSG Financial Services) -->
                </div>
              </div>
              <!--  Sub-Menu Dropdown (Contact Us)-->

              <!-- Main Nav Menu Dropdown  buttons START-->

              <!-- Financial advice dropdown button-->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('1')"
              >
                Financial advice
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- /Financial advice dropdown button--->

              <!-- Save & Invest dropdown button-->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('2')"
              >
                Save & Invest
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- /Save & Invest dropdown button--->

              <!-- Insurance dropdown button-->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('3')"
              >
                Insurance
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- /Insurance dropdown button--->

              <!--  About us dropdown button- -->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('4')"
              >
                About us
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!--  /About us dropdown button--->

              <!-- News & Publications  dropdown button-->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('5')"
              >
                News & Publications
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- News & Publications  dropdown button-->

              <!-- Careers  dropdown button-->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('6')"
              >
                Careers
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- /Careers dropdown button--->

              <!-- Contact Us  dropdown button--->
              <a
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="button width-100 nav-large mb-3"
                (click)="showMobileSubMenu('7')"
              >
                Contact us
                <icon-font name="icon-angle-right" colour="black"></icon-font>
              </a>
              <!-- Contact Us dropdown button--->

              <!-- Main Nav Menu Dropdown  buttons END-->
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button
                class="button outline w-100"
                [routerLink]="['/forms-and-Facts-sheets']"
                (click)="toggleMobileNav('mobileNav')"
              >
                Forms & fact sheets
              </button>
            </div>
            <div class="col-6">
              <button
                class="button outline w-100"
                (click)="toggleMobileNav('mobileNav')"
                [routerLink]="['/financial-advice/find-an-adviser']"
              >
                Find an adviser
              </button>
            </div>
            <!-- <div class="col-12 mt-2">
              <button
                class="button solid w-100"
                (click)="toggleMobileNav('mobileNav')"
                [routerLink]="['/getting-started']"
                routerLinkActive="active outline"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Start your journey
              </button>
            </div> -->
          </div>
        </div>

        <!-- Hamburger Button -->
        <div
          class="hamburger-button-container d-lg-none"
          (click)="toggleMobileNav('mobileNav')"
        >
          <button class="button simple-icon">
            <icon-font
              name="icon-menu"
              colour="black"
              class="medium-heading m-n3"
              *ngIf="!mobileNavOpen"
            ></icon-font>
            <icon-font
              name="icon-exit"
              colour="black"
              class="body-large"
              *ngIf="mobileNavOpen"
            ></icon-font>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Article tags component -->
<app-article-tags-modal></app-article-tags-modal>
