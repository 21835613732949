<div class="container-xl calcRow_ mb-5">
<div class="row mt-3">
  <!-- calculator -->
  <div class="col-lg-5 offset-lg-1 col-md-6 col-sm-12 mb-4">
    <div class="blur-card">
      <div class="row">
        <div class="col-md-12">
          <h4 class="cyan">{{ content?.header?.heading }}</h4>
        </div>
      </div>
      <form class="mt-3" [formGroup]="retireCalculatorForm">
        <!-- How old are you? -->
        <form-field>
          <label class="required d-block">How old are you?</label>
          <input
            formControlName="age_input"
            type="number"
            placeholder="25 years"
            min="0"
            step="1"
            class="text-align-left"
            [ngClass]="{ 'is-invalid': formValidation.age_input.errors }"
          />

          <div
            *ngIf="
              formValidation.age_input.errors &&
              formValidation.age_input.touched
            "
            class="invalid-feedback"
          >
            <form-error *ngIf="formValidation.age_input.errors.required"
              >This field is required
            </form-error>
            <form-error *ngIf="formValidation.age_input.errors.pattern"
              >Please enter a positive number of years
            </form-error>
          </div>
        </form-field>

        <!-- At what age would you like to retire? -->
        <form-field>
          <label class="required d-block"
            >At what age would you like to retire?</label
          >
          <input
            formControlName="rage_input"
            type="number"
            placeholder="65 years"
            step="1"
            min="65"
            class="text-align-left"
            [ngClass]="{ 'is-invalid': formValidation.rage_input.errors }"
            (input)="updateAge($event)"
          />

          <div
            *ngIf="
              formValidation.rage_input.errors &&
              formValidation.rage_input.touched
            "
            class="invalid-feedback"
          >
            <form-error *ngIf="formValidation.rage_input.errors.required"
              >This field is required
            </form-error>
            <form-error *ngIf="formValidation.rage_input.errors.pattern"
              >Please enter a positive number of years
            </form-error>
            <form-error *ngIf="formValidation.rage_input.errors.min">
              Minimum retirement age is 65 years old
            </form-error>
            <form-error *ngIf="formValidation.rage_input.errors.mustbeGreater"
              >Retirement age must be greater than age
            </form-error>
          </div>
        </form-field>

        <!-- How much monthly income -->
        <form-field>
          <label class="required d-block"
            >How much monthly income, in today's money, do you want to have
            available in retirement (before tax)?</label
          >
          <span class="prefix">R</span>
          <input
            type="number"
            placeholder="0"
            min="0"
            step="1000"
            formControlName="rsal_input"
            class="text-align-left"
            [ngClass]="{ 'is-invalid': formValidation.rsal_input.errors }"
          />

          <div
            *ngIf="
              formValidation.rsal_input.errors &&
              formValidation.rsal_input.touched
            "
            class="invalid-feedback mb-5"
          >
            <form-error *ngIf="formValidation.rsal_input.errors.required">
              This field is required
            </form-error>
            <form-error *ngIf="formValidation.rsal_input.errors.pattern"
              >Please enter a positive monthly income
            </form-error>
          </div>
        </form-field>

        <!-- How many years would you like your retirement income to last? (Drag to select)  -->

        <form-field class="remove-form-spacing">
          <div class="d-flex align-items-center">
            <label class="required"
              >How many years would you like your retirement income to last?
              (Drag to select)
            </label>
            <p class="slider-value body-bold body-med text-center">
              {{ yearsInvested }}
            </p>
          </div>
        </form-field>

        <div class="d-flex justify-content-between">
          <P class="slide-label pl-2">0</P>
          <P class="slide-label pr-2">50</P>
        </div>
        <range-slider-single
          class="remove-range-spacing"
          min="0"
          max="50"
          step="1"
          [value]="yearsInvested"
          (onChange)="investedYearsValue($event)"
        >
        </range-slider-single>
        <form-error *ngIf="invalidYearsInvested && !resetFormClicked"
          >Please select the number of years invested
        </form-error>

        <!-- How much have you already saved -->
        <form-field>
          <label
            class="required d-block"
            [ngClass]="{
              'mt-5': invalidYearsInvested === true && !resetFormClicked
            }"
            >How much have you already saved towards your retirement?</label
          >
          <span class="prefix">R</span>
          <input
            type="number"
            placeholder="0"
            min="0"
            step="1000"
            formControlName="savings_input"
            class="text-align-left"
            [ngClass]="{ 'is-invalid': formValidation.savings_input.errors }"
          />
          <div
            class="mt-5"
            *ngIf="
              formValidation.savings_input.errors &&
              formValidation.savings_input.touched
            "
            class="invalid-feedback"
          >
            <form-error *ngIf="formValidation.savings_input.errors.required"
              >This field is required
            </form-error>
            <form-error *ngIf="formValidation.savings_input.errors.pattern"
              >Please enter a positive savings amount
            </form-error>
          </div>
        </form-field>

        <!-- How much can you contribute -->
        <form-field>
          <label class="required d-block"
            >How much can you contribute towards your retirement every
            month?</label
          >
          <span class="prefix">R</span>
          <input
            type="number"
            placeholder="0"
            min="0"
            step="1000"
            formControlName="cont_input"
            class="text-align-left"
            [ngClass]="{ 'is-invalid': formValidation.cont_input.errors }"
          />
          <div
            *ngIf="
              formValidation.cont_input.errors &&
              formValidation.cont_input.touched
            "
            class="invalid-feedback"
          >
            <form-error *ngIf="formValidation.cont_input.errors.required"
              >This field is required
            </form-error>
            <form-error *ngIf="formValidation.savings_input.errors.pattern"
              >Please enter a positive contribution amount
            </form-error>
          </div>
        </form-field>

        <!--Calculate & Find an adviser buttons -->
        <div class="row no-gutters mb-2 mt-4">
          <div class="col-6 pr-1">
            <button-text
              buttonStyle="solid"
              fill="true"
              label="Calculate"
              (click)="calculateMonthlySavings()"
            >
            </button-text>
          </div>
          <div class="col-6 pl-1">
            <button-text
              buttonStyle="outline"
              fill="true"
              label="Reset"
              (click)="resetForm()"
            ></button-text>
          </div>
        </div>

        <!--call me back & Send me my results -->
        <div class="row no-gutters mb-2 mt-4">
          <div class="col-6 pr-1">
            <button-text
              buttonStyle="outline"
              fill="true"
              label="Call me back"
              data-toggle="modal"
              data-target="#modal-call-me-back"
              (click)="$event.preventDefault()"
            ></button-text>
          </div>
          <div class="col-6 pl-1">
            <button-text
              buttonStyle="solid"
              fill="true"
              label="Send me my results"
              (click)="toggleResultsForm()"
              [disabled]="!final_results_new_monthly_income"
            ></button-text>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- /calculator -->

  <div class="col-lg-5 col-md-6 col-12">
      <div class="blur-card mb-4">
        <div>
          <p class="body-bold body-med mb-1 slate">
            To retire at
            <span class="cyan">{{ retirement_age }}</span> you need to make a
            contribution of:
          </p>
          <p class="body-med">
            <span class="calcValue"
              >R{{ final_results_monthly_contribution_out }}</span
            >
            per month
          </p>
        </div>

        <div class="">
          <p class="body-bold body-med mb-1 slate">
            Saving
            <span class="cyan">R{{ cont_input_value_output }}</span> per month
            will result in a monthly income of:
          </p>
          <p class="calcValue">R{{ final_results_new_monthly_income_out }}</p>
        </div>

        <div class="">
          <p class="body-bold body-med mb-1 slate">
            Or you will need to work until you are:
          </p>
          <p class="calcValue">
            <span class="cyan">{{ final_results_work_until_you_are }}</span>
            <span class="mt-2" *ngIf="final_results_work_until_you_are > 0">
              years old</span
            >
          </p>
        </div>

        <div class="">
          <p class="body-bold body-small slate">
            *read more
            <a
              href=""
              class="textlink"
              data-toggle="modal"
              data-target="#modal-standard-lg-assumptions"
            >
              about calculations</a
            >
          </p>
        </div>
      </div>

      <div
        class="blur-card"
        *ngIf="sendResults && final_results_new_monthly_income > 0"
      >
        <div>
          <h3 class="small-heading mb-4">
            Fill in the form to receive your results
          </h3>
        </div>
        <form [formGroup]="sendMeResultsForm">
          <form-field>
            <label>First name</label>
            <input
              type="text"
              placeholder="Enter your first name"
              formControlName="firstName"
            />
            <div
              *ngIf="
                resultsFormValidation.firstName.touched &&
                resultsFormValidation.firstName.errors
              "
            >
              <form-error
                *ngIf="sendMeResultsForm.controls.firstName?.errors?.required"
                >This field is required
              </form-error>
            </div>
          </form-field>

          <form-field>
            <label>Last name</label>
            <input
              type="text"
              placeholder="Enter your last name"
              formControlName="surname"
            />
          </form-field>

          <form-field>
            <label>Email</label>
            <icon-font name="icon-envelope" class="suffix"></icon-font>
            <input
              type="email"
              placeholder="Enter your email"
              formControlName="email"
            />
            <div
              *ngIf="
                resultsFormValidation.email.touched &&
                resultsFormValidation.email.errors
              "
            >
              <form-error
                *ngIf="sendMeResultsForm.controls.email?.errors?.required"
                >This field is required
              </form-error>
              <form-error
                *ngIf="sendMeResultsForm.controls.email?.errors?.pattern"
                >Invalid email address</form-error
              >
            </div>
          </form-field>
          <div class="mt-4">
            <button-text
              buttonStyle="solid"
              fill="true"
              label="Submit"
              [disabled]="sendMeResultsForm.invalid"
              (click)="sendMyResults()"
            >
            </button-text>
          </div>
        </form>
      </div>
  </div>
</div>
</div>

<!-- assumptions modal -->
<div
  class="modal fade"
  id="modal-standard-lg-assumptions"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Calculator Disclaimer</h5>
      </div>
      <!-- / modal header -->

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <p>
          The PSG Investment and Retirement Tool is for information purposes
          only and does not constitute advice.
        </p>
        <p>
          Using standard financial calculations, it provides an estimated
          savings target in order to understand the savings requirements that
          must be met to achieve the estimated target. The tool is based on
          multiple assumptions:
        </p>
        <ul class="dot-list">
          <li>Inflation: 5% p.a.</li>
          <li>
            Salary inflation for retirement investing: 6% p.a. (inflation plus
            1% allowing for career growth)
          </li>
          <li>
            Your monthly pension income in retirement increases at 6% p.a.
          </li>
          <li>Returns:</li>
          <ul>
            <li>
              Short-term returns: 1 - 2 years, 6% return p.a. (1% real return)
            </li>
            <li>
              Medium-term returns: 3 - 5 years, 8% return p.a. (3% real return)
            </li>
            <li>
              Long-term returns: 6 years or more, 10% return p.a. (5% real
              return)
            </li>
          </ul>
        </ul>
        <p>
          In line with these assumptions, the tool does not take the following
          factors into consideration:
        </p>
        <ul class="dot-list">
          <li>Tax implications of investments for investors.</li>
          <li>
            Investment or product fees (i.e. all estimates are based on gross
            returns).
          </li>
          <li>
            Advantages or limitations relating to specific investment products.
          </li>
          <li>
            Volatility in investment returns due to asset mix and time horizons.
          </li>
          <li>
            Different inflation rates applicable to various items and time
            horizons.
          </li>
        </ul>
        <p>
          Should you wish to rely on the calculations from this tool, you are
          advised to check the calculations before using them as PSG will not be
          liable for any inaccuracy in the calculation.
        </p>
        <p>
          Speak to your financial adviser before making an investment decision.
          We don't guarantee that the information or any investment product is
          suitable or valuable to any investors. You should consult a registered
          financial adviser to get advice about whether our investment plans and
          the funds are suitable for your personal investment needs.
        </p>
      </div>
      <!-- / modal body -->

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button type="button" class="button solid" data-dismiss="modal">
          Close
        </button>
      </div>
      <!-- / modal footer -->
    </div>
    <!-- / modal container -->
  </div>
</div>

<!-- email sent confirmation modal -->
<div
  class="modal fade"
  id="result-modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Email Status</h5>
      </div>

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <h4>{{ contactUsResponseMsg }}</h4>
      </div>

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--  -->

<app-call-me-back-modal></app-call-me-back-modal>