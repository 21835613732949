<!-- Footer -->
<div class="container-fluid">
  <!-- Footer Main Body -->
  <div
    class="d-flex justify-content-center align-items-center row px-3 white-bg-30"
  >
    <div class="container-xl">
      <div class="row">
        <div class="col-12 col-md">
          <a class="navbar-brand mb-3 d-none d-md-block" href="#"
            ><img
              class="logo-img"
              src="/assets/images/logo/PSG-Financial-Services.svg"
              alt="PSG Financial Services"
          /></a>
          <a
            href="tel: +27 (21) 918 7800"
            class="button small nav-left-icon w-100 pt-1 col col-md"
            style="color: #00afef !important"
          >
            <icon-font
              name="icon-phone"
              colour="black"
              class="body-large"
            ></icon-font>
            +27 (21) 918 7800
          </a>
          <button
            (click)="mailInfo()"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="button small nav-left-icon w-100 pt-1 col col-md"
          >
            <icon-font
              name="icon-envelope"
              colour="black"
              class="body-large"
            ></icon-font
            >info@psg.co.za
          </button>
          <button
            aria-haspopup="true"
            aria-expanded="false"
            class="button small nav-left-icon w-100 pt-1 col col-md"
            style="
              max-height: 10rem;
              align-items: flex-start;
              line-height: 1.4em;
            "
          >
            <icon-font
              name="icon-location"
              colour="black"
              class="body-large"
            ></icon-font>
            PSG Head Office <br />
            The Edge, 3 Howick Close<br />
            Tyger Waterfront<br />
            Bellville<br />
            7530
          </button>
        </div>
        <div class="dropdown-divider col-12 my-4 d-md-none"></div>
        <div class="col">
          <p class="body-bold mb-2">About PSG</p>
          <div class="devider-horizontal"></div>
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['/asset-management/about-us']"
            >PSG Asset Management</a
          >
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['/about-us/psg-insure']"
            >PSG Insure</a
          >
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['/about-us/psg-wealth']"
            >PSG Wealth</a
          >
          <a class="button small width-100 nav-left-mini" (click)="goToLogin()"
            >myPSG</a
          >
        </div>
        <div class="col">
          <p class="body-bold mb-2">Support</p>
          <div class="devider-horizontal"></div>
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['support/faq']"
            >FAQ</a
          >
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['support/legal']"
            >Legal</a
          >
          <a
            class="button small width-100 nav-left-mini"
            [routerLink]="['support/glossary']"
            >Glossary</a
          >
        </div>
        <div class="dropdown-divider col-12 my-4 d-md-none"></div>
        <div class="col-12 col-md">
          <p class="body-bold d-none d-md-block mb-2">Stay Informed</p>
          <div class="devider-horizontal d-none d-md-block"></div>
          <div class="row my-md-4">
            <p class="body-small col-7 col-md-12 mb-3 text-center text-md-left">
              Sign up for our newsletters and receive information on finance.
            </p>
            <button
              class="w-100 mx-md-3 button outline"
              (click)="goToSubscriptionsUrl()"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Social Media -->
  <div class="dropdown-divider m-0"></div>
  <div
    class="d-flex justify-content-center align-items-center row pt-3 white-bg"
  >
    <button class="button simple-icon" (click)="goToLinkedIn()">
      <img
        class="icon"
        src="\assets\images\linkedin.png"
        alt="instagram-icon"
      />
    </button>
    <button class="button simple-icon" (click)="goToTwitter()">
      <img class="icon" src="\assets\images\x.png" alt="x-icon" />
    </button>
    <button class="button simple-icon" (click)="goToFacebook()">
      <img
        class="icon"
        src="\assets\images\facebook.png"
        alt="instagram-icon"
      />
    </button>
    <button class="button simple-icon" (click)="goToYoutube()">
      <img class="icon" src="\assets\images\youtube.png" alt="youtube-icon" />
    </button>
    <button class="button simple-icon" (click)="goToSpotify()">
      <img class="icon" src="\assets\images\spotify.png" alt="spotify-icon" />
    </button>
    <button class="button simple-icon" (click)="goToInstagram()">
      <img
        class="icon"
        src="\assets\images\instagram.png"
        alt="instagram-icon"
      />
    </button>
  </div>
  <!-- Footer Legal -->
  <div
    class="d-flex justify-content-center align-items-center row pb-3 px-3 pt-2 white-bg"
  >
    <span class="body-small slate text-center">
      &copy;{{ currentYear }} PSG Financial Services Limited. All rights
      reserved. Affiliates of PSG Financial Services, a licensed controlling
      company, are authorised financial services providers.
    </span>
  </div>
</div>
