import { Component, Input, OnInit } from "@angular/core";
import { take } from "rxjs";
import { DailyInvestmentService } from "src/app/services/dailyInvestment/daily-investment.service";
import { FundTablesOverviewModel } from "src/app/services/dailyInvestment/daily-ivestment.model";

@Component({
  selector: 'overview-table',
  templateUrl: './overview-table.component.html',
  styles: []
})
export class OverviewTableComponent implements OnInit {

  @Input() locality: string;
  @Input() category: string;
  @Input() showclass: boolean = false;

  tableCategory: string;

  public overViewData: FundTablesOverviewModel;

  constructor(private dailyInvestmentService: DailyInvestmentService) { }

  public ngOnInit() {

    this.tableCategory = 'Morningstar Category';
    if (this.locality == 'OTHER-CURRENCIES' && this.category == 'PSG-FUND') {
      this.tableCategory = 'Morningstar category';
    }
    else if (this.locality == 'OTHER-CURRENCIES' && this.category == 'MULTI-MANAGER-FUND') {
      this.tableCategory = 'Morningstar category';
    }

    this.dailyInvestmentService.getFundsOverviewData({
      date: "",
      fundCategory: this.category,
      locality: this.locality,
      pageNumber: 1,
      pageSize: 1000
    }).pipe(
      take(1))
      .subscribe((result) => {
        this.overViewData = result;
      })
  }
}
