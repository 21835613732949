<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- all funds section -->
<div class="container-fluid zoomout-banner-m">
  <div
    class="container-fluid contactUs"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title mega-title--mobile text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
          <h2
            class="text-shadow"
            [ngClass]="
              content?.header?.subheadingColour
                ? content?.header?.subheadingColour
                : 'white'
            "
          >
            {{ content?.header?.subheading }}
          </h2>
        </div>
        <div class="pl-3 pl-md-4">
          <div
            [innerHTML]="content?.header?.excerpt"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- all funds main content section -->

<!-- contact us card -->
<div *ngIf="!isPageLoading" class="container-xl contactUs--pt_ py-5">
  <div class="row">
    <div class="col-md-12">
      <div class="contact-Us-Card">
        <form class="example-form" [formGroup]="contactUsForm">
          <div class="row">
            <div class="col-md-4 offset-md-2 text-left">
              <h2>My name is</h2>
            </div>
            <div class="col-md-4">
              <h2>
                <input
                  type="text"
                  formControlName="name"
                  class="input-bottom-border"
                  id="name"
                  name="name"
                  required
                />
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 offset-md-2 text-left">
              <h2>please assist me with</h2>
            </div>
            <div class="col-md-4">
              <h2>
                <input
                  type="text"
                  formControlName="assitance"
                  class="input-bottom-border"
                  id="message"
                  name="message"
                  required
                />
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 offset-md-2 text-left">
              <h2>my email is</h2>
            </div>
            <div class="col-md-4">
              <h2>
                <input
                  type="email"
                  formControlName="emailAddress"
                  class="input-bottom-border"
                  required
                />
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 offset-md-2 text-left">
              <h2>or call me at</h2>
            </div>
            <div class="col-md-4">
              <h2>
                <input
                  class="input-bottom-border"
                  type="tel"
                  formControlName="phoneNumber"
                  id="phone"
                  name="phone"
                  required
                />
              </h2>
            </div>
          </div>
          <button
            class="col col-sm-auto button solid text-center mt-4"
            (click)="findAnAdviserClicked()"
            [disabled]="!contactUsForm.valid"
          >
            Send Message
          </button>
          <div class="mt-4">
            <h4>Please see below for further contact information.</h4>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="row mt-5"
    *ngIf="!content?.header?.actions || content?.header?.actions.length > 0"
  >
    <div class="col-md-12">
      <p>
        Please click on the buttons below if you know what you are looking for
        to receive quicker assistance.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ng-container
        *ngFor="let action of content?.header?.actions | slice : 0 : 5"
      >
        <button-text
          class="mr-2 mb-2"
          [buttonStyle]="
            action?.buttonColor != null
              ? action?.buttonColor
              : action.isPrimary
              ? 'solid'
              : 'outline'
          "
          [label]="action?.label"
          (click)="DynamicButtonClick(action)"
        >
        </button-text>
      </ng-container>
    </div>
  </div>
</div>
<!-- contact us card-->

<div class="container-fluid my-3 py-3 my-md-5 py-md-5 white-bg"></div>

<!-- PSG Wealth contact details-->
<div *ngIf="!isPageLoading" class="container-fluid svg-bg contactUs mb-5 h-100">
  <div class="container-xl">
    <div class="row pt-5">
      <div class="col-lg-6 col-md-6">
        <div>
          <h1>{{ content?.body?.offices[0]?.name }}</h1>
        </div>
        <div class="row pt-4">
          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Contact Details</h4>
            <a
              class="body-small cyan mt-0"
              href="tel:{{ content?.body?.offices[0]?.contactNumber }}"
              >{{ content?.body?.offices[0]?.contactNumber }}</a
            >
            <h4 class="body-bold body-large mt-3 mb-2">GPS</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[0]?.gpsCoordinates"
            ></p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Postal Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[0]?.postalAddress"
            ></p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Physical Address</h4>
            <p
              class="body-small body-text"
              [innerHTML]="content?.body?.offices[0]?.physicalAddress"
            ></p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="col-md-5 div-line"></div>
        <div>
          <img
            class="img-cover contact-img significant-raise"
            [src]="content?.body?.offices[0]?.image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid">
  <div class="container-xl">
    <div class="row">
      <div
        class="col-md-4 col-lg-4 mb-4"
        *ngFor="let department of content?.body?.offices[0]?.departments"
      >
        <h4 class="cyan body-bold body-large">{{ department?.name }}</h4>
        <p class="body-small mb-0" [innerHTML]="department?.content"></p>
        <a
          class="body-small cyan body-bold mt-0"
          href="tel:{{ department?.contactNumber }}"
          >{{ department?.contactNumber }}</a
        >
        <p class="body-small cyan body-bold"><a href="mailto:{{ department?.emailAddress}}">{{ department?.emailAddress }}</a></p>
      </div>
    </div>
  </div>
</div>
<contact-us-downloads
  *ngIf="showDownloadAccordionControl(content?.body?.offices[0])"
  [heading]="content?.body?.offices[0]?.documentsHeading"
  [downloads]="content?.body?.offices[0]?.downloads"
>
</contact-us-downloads>
<!--PSG Wealth contact details -->

<!-- PSG Asset Management contact details-->
<div *ngIf="!isPageLoading" class="container-fluid svg-bg contactUs mb-5 h-100">
  <div class="container-xl">
    <div class="row pt-5">
      <div class="col-lg-6 col-md-6">
        <div>
          <h1>{{ content?.body?.offices[1]?.name }}</h1>
        </div>
        <div class="row pt-4">
          <div class="col-md-4">
            <h4 class="body-bold body-large mb-1">Contact Details</h4>
            <a
              class="body-small cyan mt-0"
              href="tel:{{ content?.body?.offices[1]?.contactNumber }}"
              >{{ content?.body?.offices[1]?.contactNumber }}</a
            >

            <h4 class="body-bold body-large mt-3 mb-2">GPS</h4>
            <p class="body-small">
              {{ content?.body?.offices[1]?.gpsCoordinates }}
            </p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Postal Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[1]?.postalAddress"
            ></p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Physical Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[1]?.physicalAddress"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="col-md-4 div-line"></div>
        <div>
          <img
            class="img-cover contact-img significant-raise"
            [src]="content?.body?.offices[1]?.image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid h-100">
  <div class="container-xl h-100">
    <div class="row h-100">
      <div
        class="col-md-4 col-lg-4 mb-4"
        *ngFor="let department of content?.body?.offices[1]?.departments"
      >
        <h4 class="cyan body-bold body-large">{{ department.name }}</h4>
        <p class="body-small pl-length-sm" [innerHTML]="department.content"></p>
        <a
          class="body-small cyan body-bold mt-0"
          href="tel:{{ department?.contactNumber }}"
          >{{ department?.contactNumber }}</a
        >
        <p class="body-small cyan body-bold"><a href="mailto:{{ department?.emailAddress }}">{{ department?.emailAddress }}</a></p>
      </div>
    </div>
  </div>
</div>
<contact-us-downloads
  *ngIf="showDownloadAccordionControl(content?.body?.offices[1])"
  [heading]="content?.body?.offices[1]?.documentsHeading"
  [downloads]="content?.body?.offices[1]?.downloads"
>
</contact-us-downloads>
<!--PSG Asset Management contact details -->

<!-- PSG Insure contact details-->
<div *ngIf="!isPageLoading" class="container-fluid svg-bg contactUs mb-5 h-100">
  <div class="container-xl">
    <div class="row pt-5">
      <div class="col-lg-6 col-md-6">
        <div>
          <h1>{{ content?.body?.offices[2]?.name }}</h1>
        </div>
        <div class="row pt-4">
          <div class="col-md-4">
            <h4 class="body-bold body-large mb-1">Contact Details</h4>
            <a
              class="body-small cyan"
              href="tel:{{ content?.body?.offices[2]?.contactNumber }}"
              >{{ content?.body?.offices[2]?.contactNumber }}</a
            >

            <h4 class="body-bold body-large mt-3 mb-2">GPS</h4>
            <p class="body-small">
              {{ content?.body?.offices[2]?.gpsCoordinates }}
            </p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Postal Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[2]?.postalAddress"
            ></p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Physical Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[2]?.physicalAddress"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="col-md-5 div-line"></div>
        <div>
          <img
            class="img-cover contact-img significant-raise"
            [src]="content?.body?.offices[2]?.image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid h-100">
  <div class="container-xl h-100">
    <div class="row h-100">
      <div
        class="col-md-4 col-lg-4 mb-4"
        *ngFor="let department of content?.body?.offices[2]?.departments"
      >
        <h4 class="cyan body-bold body-large">{{ department.name }}</h4>
        <p class="body-small pl-length-sm" [innerHTML]="department.content"></p>
        <a
          class="body-small cyan body-bold mt-0"
          href="tel:{{ department?.contactNumber }}"
          >{{ department?.contactNumber }}</a
        >
        <p class="body-small cyan body-bold"><a href="mailto:{{ department?.emailAddress }}">{{ department?.emailAddress }}</a></p>
      </div>
    </div>
  </div>
</div>
<contact-us-downloads
  *ngIf="showDownloadAccordionControl(content?.body?.offices[2])"
  [heading]="content?.body?.offices[2]?.documentsHeading"
  [downloads]="content?.body?.offices[2]?.downloads"
>
</contact-us-downloads>
<!--PSG Insure contact details -->

<!-- PSG Konsult contact details-->
<div *ngIf="!isPageLoading" class="container-fluid svg-bg contactUs mb-5 h-100">
  <div class="container-xl">
    <div class="row pt-5">
      <div class="col-lg-6 col-md-6">
        <div>
          <h1>{{ content?.body?.offices[3]?.name }}</h1>
        </div>
        <div class="row pt-4">
          <div class="col-md-4">
            <h4 class="body-bold body-large mb-1">Contact Details</h4>
            <a
              class="body-small cyan mt-0"
              href="tel:{{ content?.body?.offices[3]?.contactNumber }}"
              >{{ content?.body?.offices[3]?.contactNumber }}</a
            >
            <h4 class="body-bold body-large mt-3 mb-2">GPS</h4>
            <p class="body-small">
              {{ content?.body?.offices[3]?.gpsCoordinates }}
            </p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Postal Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[3]?.postalAddress"
            ></p>
          </div>

          <div class="col-md-4">
            <h4 class="body-bold body-large mb-2">Physical Address</h4>
            <p
              class="body-small"
              [innerHTML]="content?.body?.offices[3]?.physicalAddress"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="col-md-5 div-line"></div>
        <div>
          <img
            class="img-cover contact-img significant-raise"
            [src]="content?.body?.offices[3]?.image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid h-100">
  <div class="container-xl h-100">
    <div class="row h-100">
      <div
        class="col-md-4 col-lg-4 mb-4"
        *ngFor="let department of content?.body?.offices[3]?.departments"
      >
        <h4 class="cyan body-bold body-large">{{ department?.name }}</h4>
        <p
          class="body-small pl-length-sm"
          [innerHTML]="department?.content"
        ></p>
        <a
          class="body-small cyan body-bold mt-0"
          href="tel:{{ department?.contactNumber }}"
          >{{ department?.contactNumber }}</a
        >
        <p class="body-small cyan body-bold"><a href="mailto:{{ department?.emailAddress }}">{{ department?.emailAddress }}</a></p>
      </div>
    </div>
  </div>
</div>
<contact-us-downloads
  *ngIf="showDownloadAccordionControl(content?.body?.offices[3])"
  [heading]="content?.body?.offices[3]?.documentsHeading"
  [downloads]="content?.body?.offices[3]?.downloads"
>
</contact-us-downloads>
<!--PSG Konsult contact details -->

<div
  *ngIf="!isPageLoading"
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="contactUsResponse && !isInitLoading">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Email Status</h5>
      </div>

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <h4>{{ contactUsResponseMsg }}</h4>
      </div>

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
