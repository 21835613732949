import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentYear: Number = new Date().getFullYear();

  constructor() { }

  ngOnInit() { }

  goToFacebook() {
    window.open('https://www.facebook.com/PSGFinancialServices', '_blank');
  }

  goToLinkedIn() {
    window.open('https://za.linkedin.com/company/psg-konsult', '_blank');
  }

  goToTwitter() {
    window.open('https://twitter.com/PSGfinservices', '_blank');
  }

  goToYoutube() {
    window.open('https://www.youtube.com/@PSGFinancialServices', '_blank');
  }

  goToInstagram() {
    window.open('https://www.instagram.com/psgfinancialservices/', '_blank');
  }
  goToSpotify() {
    window.open('https://open.spotify.com/show/2uFwu04s7A8mJFkb6cXr3P?si=95ad97cedba54aa7', '_blank');
  }

  goToLogin() {
    window.open('https://my.psg.co.za', '_blank');
  }

  mailInfo() {
    window.location.href = "mailTo:info@psg.co.za";
  }

  goToSubscriptionsUrl() {
    window.open('https://beta.psg.co.za/secure/comms-ui/manage-subscriptions', '_blank');
  }

}
