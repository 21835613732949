import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EmailTemplate } from "src/app/models/content/email/email-templates.model";
import { RetirementCalculator } from "src/app/models/content/save-and-invest/retirement-calculator-model";
import { SaveForRetirementComponent } from "src/app/models/content/save-and-invest/save-for-retirement-component.model";
import { CalculatorService } from "src/app/services/calculator/calculator.service";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
declare var $: any;

@Component({
  selector: "app-retirement-calculator",
  templateUrl: "./retirement-calculator.component.html",
})
export class RetirementCalculatorComponent implements OnInit {
  public invalidYearsInvested: boolean = false;
  public resetFormClicked: boolean = false;
  public isInitLoading: boolean = true;
  private EmailTemp = new EmailTemplate();
  public content: any = {};
  public shareHeadline: string;

  //constant variable

  public yearsInvested: number = 0;
  public sendResults: boolean = false;
  public inv_returns_input: number = 0.1;
  public cont_input_value_output: string = "0";
  public rage_input_value_output: number = 0;
  public sav_cur_input: number;
  public retirement_age: number = 0;
  public curr_age: number = 0;
  public retireCalculatorForm: FormGroup;

  //send email settings
  public sendMeResultsForm: FormGroup;
  public contactUsResponseMsg: string = "";

  //inputs

  private comp: number;
  private inv_comp: number;
  private irn: number;
  private sav_acc: number;
  private pv_factor: number;
  private peny: number;
  private pen_pres: number;
  private start_pen: number;
  private rage_new: number = 0;

  private irs: number;
  private ypen: number;
  private PV_pen: number;
  private sav_factor: number;
  private real_return: number;
  private invalidRage: boolean = false;

  //results to be displayed
  public final_results_monthly_contribution: number = 0; //Or you will need to work until you are:
  public final_results_monthly_contribution_out: string = "0"; //Or you will need to work until you are:
  public final_results_work_until_you_are: number = 0; //Or you will need to work until you are:
  public final_results_new_monthly_income: number = 0; //Saving that amount you will either be able to retire with a monthly income (in today's money) of:
  public final_results_new_monthly_income_out: string = "0";
  public final_rage_new_result: number;

  constructor(
    private formBuilder: FormBuilder,
    private calculatorService: CalculatorService,
    private seoService: SeoService,
    private pageService: PageService
  ) {

  }
  ngOnInit() {

    this.comp = 1;
    this.BuildLeadForm();
    this.BuildsendMeResultsForm();

    this.pageService
      .GetPage<SaveForRetirementComponent>(this.pageService.URIs.saveForRetirement)
      .subscribe((content) => {
        this.content = new SaveForRetirementComponent(content);
        this.shareHeadline = this.content?.header?.heading?.replace("&", "%26");
        this.seoService.updateMetaInfoForPage(
          this.content?.seo?.title,
          window.location.href,
          this.content?.header?.image,
          this.content?.seo?.description
        );
      });

  }

  error: any = {
    isError: false,
    errorMessage: "",
  };

  private BuildLeadForm(): void {
    this.retireCalculatorForm = this.formBuilder.group(
      {
        rage_input: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[1-9]\d*$/),
            Validators.min(65),
          ]),
        ],
        age_input: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[1-9]\d*$/),
          ]),
        ],
        rsal_input: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[1-9]\d*$/),
          ]),
        ],
        cont_input: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[1-9]\d*$/),
          ]),
        ],
        savings_input: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(/^[0-9]\d*$/),
          ]),
        ],
      },
      {
        validator: Validators.compose([this.validateRage.bind(this)]),
      }
    );
  }

  private BuildsendMeResultsForm(): void {
    this.sendMeResultsForm = this.formBuilder.group({
      firstName: ["", Validators.compose([Validators.required])],
      surname: [""],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
      ],
    });
  }

  private MarkLeadFormAsTouched(): void {
    this.retireCalculatorForm.controls.rage_input.markAsTouched();
    this.retireCalculatorForm.controls.rsal_input.markAsTouched();
    this.retireCalculatorForm.controls.cont_input.markAsTouched();
    this.retireCalculatorForm.controls.age_input.markAsTouched();
    this.retireCalculatorForm.controls.savings_input.markAsTouched();
  }

  // convenience getter for easy access to form fields
  get formValidation() {
    return this.retireCalculatorForm.controls;
  }

  get resultsFormValidation() {
    return this.sendMeResultsForm.controls;
  }

  public resetForm() {
    this.retireCalculatorForm.reset();
    this.investedYearsValue(0);
    this.resetFormClicked = true;
    this.retirement_age = 0;
    this.final_results_monthly_contribution = 0;
    this.final_results_monthly_contribution_out = "0";
    this.cont_input_value_output = "0";
    this.final_results_new_monthly_income = 0;
    this.final_results_new_monthly_income_out = "0";
    this.final_results_work_until_you_are = 0;
    this.sendMeResultsForm.reset();
    this.sendResults = false;
  }

  //age validator
  validateRage(fb: FormGroup) {
    let age_v = fb.get("age_input");
    let rage_v = fb.get("rage_input");

    if (Number(age_v.value) > Number(rage_v.value))
      rage_v.setErrors({ mustbeGreater: true });
    else rage_v.setErrors(null);
  }

  public updateAge(e) {
    this.retirement_age = e.target.value;
  }

  private commaSeparated(amount: number) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public calculateMonthlySavings() {
    this.MarkLeadFormAsTouched();

    if (this.yearsInvested <= 0) {
      this.invalidYearsInvested = true;
      return;
    }

    if (this.retireCalculatorForm.invalid) {
      return;
    }

    let comp_time_value = "monthly";
    let d_age: number;
    let rage_input_value: number;
    let i_age_input_value: number;
    let rsal_input_value: number;
    let age_input_value: number;
    let savings_value: number;
    let cont_input_value: number;

    //property declaration
    let inv_returns_input_value = this.inv_returns_input;
    i_age_input_value = this.yearsInvested;
    rage_input_value = this.retireCalculatorForm.controls["rage_input"].value;
    rsal_input_value = this.retireCalculatorForm.controls["rsal_input"].value;
    cont_input_value = this.retireCalculatorForm.controls["cont_input"].value;
    age_input_value = this.retireCalculatorForm.controls["age_input"].value;
    savings_value = this.retireCalculatorForm.controls["savings_input"].value;

    d_age = Number(i_age_input_value) + Number(rage_input_value);
    this.cont_input_value_output = this.commaSeparated(cont_input_value);
    this.rage_input_value_output = rage_input_value;

    //calculate the number of years investing
    let years: Number = Number(rage_input_value - age_input_value);

    //fixed investment returns
    if (Number(years) >= 1 && Number(years) <= 2) {
      inv_returns_input_value = 0.06;
      this.real_return = 1;
    } //short-term returns

    if (Number(years) >= 3 && Number(years) <= 5) {
      inv_returns_input_value = 0.08;
      this.real_return = 3;
    } //medium-term returns

    if (Number(years) >= 6) {
      inv_returns_input_value = 0.1;
      this.real_return = 5;
    } //ong-term returns

    //compound factor for compound investment
    switch (comp_time_value as any) {
      case "monthly":
        this.comp = 12;
        break;
      case "daily":
        this.comp = 365.25;
        break;
      case "quarterly":
        this.comp = 4;
        break;
      case "yearly":
        this.comp = 1;
        break;
      default:
        this.comp = 1;
        break;
    }

    //Discount rate allowing for salary annual growth
    this.inv_comp =
      Math.pow(1 + inv_returns_input_value / this.comp, this.comp) - 1;

    this.irs = (1 + this.inv_comp) / (1 + RetirementCalculator.sal_gro) - 1;

    //Monthly pension income at retirement (Retirement age as PV) and then converted to yearly pension
    let mpen =
      rsal_input_value *
      Math.pow(
        1 + RetirementCalculator.sal_gro,
        rage_input_value - age_input_value
      );
    this.ypen = mpen * 12;

    //Present value of pension payments (Retirement age as PV) (an formula) * inv_returns = investment return while irs allows for salary growth
    this.PV_pen =
      (this.ypen * (1 - Math.pow(1 + this.irs, -(d_age - rage_input_value)))) /
      (this.irs / (1 + this.irs)); // Formula 1.9

    //Accumulated value of retirement savings as on retirement ag
    let sav_cur_acc =
      savings_value *
      Math.pow(1 + this.inv_comp, rage_input_value - age_input_value); //accumulation of current savings

    this.sav_factor =
      ((Math.pow(1 + this.inv_comp, rage_input_value - age_input_value) -
        Math.pow(
          1 + RetirementCalculator.sal_gro,
          rage_input_value - age_input_value
        )) /
        (this.inv_comp - RetirementCalculator.sal_gro)) *
      (1 + this.inv_comp);

    //pv_pen = fut_savings
    this.final_results_monthly_contribution =
      (this.PV_pen - sav_cur_acc) / this.sav_factor;
    this.final_results_monthly_contribution /= 12;
    this.final_results_monthly_contribution = Number(
      this.final_results_monthly_contribution.toFixed(2)
    );

    this.final_results_monthly_contribution_out = this.commaSeparated(
      this.final_results_monthly_contribution
    );

    this.calculateNewMonthlySavings();
  }

  public calculateNewMonthlySavings() {
    let sav_cur_acc: number;
    let comp_time_value = "monthly";
    let d_age: number;

    let rage_input_value: number;
    let i_age_input_value: number;
    let rsal_input_value: number;
    let age_input_value: number;
    let savings_value: number;
    let cont_input_value: number;
    //property declaration
    let inv_returns: number = 0;

    //property declaration
    let inv_returns_input_value = this.inv_returns_input;
    i_age_input_value = this.yearsInvested;
    rage_input_value = this.retireCalculatorForm.controls["rage_input"].value;
    rsal_input_value = this.retireCalculatorForm.controls["rsal_input"].value;
    cont_input_value = this.retireCalculatorForm.controls["cont_input"].value;
    age_input_value = this.retireCalculatorForm.controls["age_input"].value;
    savings_value = this.retireCalculatorForm.controls["savings_input"].value;

    //calculate the number of years investing
    let years: number = Number(rage_input_value - age_input_value);

    //fixed investment returns
    if (years >= 1 && years <= 2) {
      inv_returns_input_value = 0.06;
      this.real_return = 1;
    } //short-term returns

    if (years >= 3 && years <= 5) {
      inv_returns_input_value = 0.08;
      this.real_return = 3;
    } //medium-term returns

    if (years >= 6) {
      inv_returns_input_value = 0.1;
      this.real_return = 5;
    } //ong-term returns

    d_age = Number(i_age_input_value) + Number(rage_input_value);
    this.cont_input_value_output = this.commaSeparated(cont_input_value);
    this.rage_input_value_output = rage_input_value;

    //compound factor for compound investment
    switch (comp_time_value) {
      case "monthly":
        this.comp = 12;
        break;
      case "daily":
        this.comp = 365.25;
        break;
      case "quarterly":
        this.comp = 4;
        break;
      case "yearly":
        this.comp = 1;
        break;
      default:
        this.comp = 1;
        break;
    }

    //accumulated value at retirement based on contributions
    this.inv_comp =
      Math.pow(1 + inv_returns_input_value / this.comp, this.comp) - 1;
    this.irn = (1 + this.inv_comp) / (1 + RetirementCalculator.sal_gro) - 1;

    this.sav_acc =
      (cont_input_value *
        12 *
        (Math.pow(1 + this.inv_comp, rage_input_value - age_input_value) -
          Math.pow(
            1 + RetirementCalculator.cont_incr,
            rage_input_value - age_input_value
          )) *
        (1 + this.inv_comp)) /
      (this.inv_comp - RetirementCalculator.cont_incr);

    //accumulation of current savings

    sav_cur_acc =
      savings_value *
      Math.pow(1 + this.inv_comp, rage_input_value - age_input_value);
    this.pv_factor =
      (1 - Math.pow(1 + this.irn, -(d_age - rage_input_value))) /
      (this.irn / (1 + this.irn));
    this.peny = (this.sav_acc + sav_cur_acc) / this.pv_factor;

    this.pen_pres =
      (this.peny *
        Math.pow(
          1 + RetirementCalculator.sal_gro,
          -(rage_input_value - age_input_value)
        )) /
      12;

    //new retirement age given contributions stay the same i.e. rage needs to change
    this.start_pen =
      rsal_input_value *
      12 *
      Math.pow(
        1 + RetirementCalculator.sal_gro,
        this.rage_new - age_input_value
      );

    //Loop through ages
    for (this.rage_new = 30; this.rage_new <= 100; this.rage_new++) {
      let pv_cont =
        ((1 - Math.pow(1 + this.irn, -(d_age - this.rage_new))) /
          (this.irn / (1 + this.irn))) *
        (rsal_input_value *
          12 *
          Math.pow(
            1 + RetirementCalculator.sal_gro,
            this.rage_new - age_input_value
          ));

      let firstVal =
        cont_input_value *
        (Math.pow(1 + this.inv_comp, this.rage_new - age_input_value) -
          Math.pow(
            1 + RetirementCalculator.sal_gro,
            this.rage_new - age_input_value
          )) *
        12;

      //
      let secondVal =
        (this.inv_comp - RetirementCalculator.sal_gro) * (1 + this.inv_comp);

      let acc_cont = firstVal / secondVal;

      let sav =
        savings_value *
        Math.pow(1 + this.inv_comp, this.rage_new - age_input_value);

      let fac = pv_cont - sav - acc_cont;

      if (fac < 0) {
        this.final_results_work_until_you_are = this.rage_new - 1;
        break;
      }
    }

    this.final_results_new_monthly_income = Number(this.pen_pres.toFixed(2));
    this.final_results_new_monthly_income_out = this.commaSeparated(
      this.final_results_new_monthly_income
    );

    //temp save results
    localStorage.setItem("age_input_value", age_input_value.toString());
    localStorage.setItem("rage_input_value", rage_input_value.toString());
    localStorage.setItem("yearsInvested", this.yearsInvested.toString());
    localStorage.setItem(
      "final_results_monthly_contribution",
      this.commaSeparated(this.final_results_monthly_contribution)
    );
    localStorage.setItem("cont_input_value", cont_input_value.toString());
    localStorage.setItem(
      "inv_returns",
      (inv_returns_input_value * 100).toString()
    );
    localStorage.setItem("real_return", this.real_return.toString());
    localStorage.setItem(
      "final_results_new_monthly_income",
      this.commaSeparated(this.final_results_new_monthly_income)
    );
    localStorage.setItem(
      "final_results_work_until_you_are",
      this.final_results_work_until_you_are.toString()
    );

    localStorage.setItem("savings_value", this.commaSeparated(savings_value));
  }

  private produceFaceBookTrackingEvent() {
    try {
      (window as any).fbq("track", "CompleteRegistration");
    } catch (err) {
      return;
    }
  }

  public sendMyResults() {
    if (this.sendMeResultsForm.invalid) {
      return;
    }

    let firstName = this.sendMeResultsForm.controls["firstName"].value;
    let email = this.sendMeResultsForm.controls["email"].value;

    this.calculatorService
      .sendComms({
        Name: `${firstName} ${this.sendMeResultsForm.controls["surname"].value}`,
        Email: email,
        ContactNumber: '',
        Message: this.EmailTemp.getRetirementCalTemplate(firstName),
        Subject: "View your calculation",
        ToAddresses: ["social.leads.reporting@psg.co.za", email],
      })
      .subscribe(
        (x) => {
          if (x) {
            this.contactUsResponseMsg = "Results successfully sent!";
            $("#result-modal-standard-sm").modal("show");
            this.produceFaceBookTrackingEvent();
          } else {
            this.contactUsResponseMsg = "Results not sent!";
            $("#result-modal-standard-sm").modal("show");
          }
          this.toggleResultsForm();
          this.sendMeResultsForm.reset();
        },
        (error) => {
          this.contactUsResponseMsg = "Results not sent!";
          $("#result-modal-standard-sm").modal("show");
        }
      );

    this.isInitLoading = false;
  }

  public investedYearsValue(years: number) {
    this.yearsInvested = years;
    if (this.yearsInvested > 0) this.invalidYearsInvested = false;
    else this.invalidYearsInvested = true;
  }

  public toggleResultsForm() {
    if (!(this.final_results_new_monthly_income > 0)) return;
    this.sendResults = !this.sendResults;
  }
}
